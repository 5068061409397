<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <textarea
        class="
          w-full
          border border-gray-300
          block
          bg-white
          h-40
          p-4
          is-focusable
        "
        :id="wId"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{
          rounded: !hasTemplates || templates.length === 0,
          'rounded-t': hasTemplates && templates.length > 0
        }"
      />
      <div
        class="bg-gray-300 rounded-b p-4"
        v-if="hasTemplates && templates.length > 0"
      >
        <selector compact small v-model="template" :options="templates" />
      </div>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextareaInput',
  props: {
    heading: Object,
    value: String,
    error: String
  },
  data() {
    return {
      template: 'please'
    }
  },
  watch: {
    template(to) {
      if (to === 'please') {
        return
      }
      this.insertTemplate(to)
      this.$nextTick(() => {
        this.template = 'please'
      })
    }
  },
  computed: {
    wId() {
      return this.heading.id
    },
    hasTemplates() {
      return !!this.templateContext
    },
    templateContext() {
      const tCtx = {
        description: 'D'
      }

      return tCtx?.[this.heading.id] ?? null
    },
    storeTemplates() {
      if (!this.hasTemplates) {
        return null
      }
      if (!this.$store.state.loaded) {
        return []
      }

      return this.$store.state.templates.items.filter(
        (item) => item.context === this.templateContext
      )
    },
    templates() {
      const _templates = this.storeTemplates.map((item) => ({
        id: item._id,
        value: item._id,
        label: item.name
      }))
      return [
        {
          id: 'please',
          value: 'please',
          label: this.$t('general.pleaseSelect')
        },
        ..._templates
      ]
    }
  },
  methods: {
    insertTemplate(templateId) {
      const _tpl = this.storeTemplates.find((item) => item._id === templateId)
      if (!_tpl) {
        return
      }
      this.$emit('input', `${this.value ?? ''}${_tpl.body}`)
    }
  }
}
</script>
