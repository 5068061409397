<template>
  <div class="w-full mb-2">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div
      class="
        w-full
        rounded
        border
        block
        bg-white
        min-h-input
        p-2
        border-gray-300
      "
      :id="wId"
    >
      <div
        class="
          rounded
          tag
          inline-flex
          items-center
          text-sm
          mx-2
          my-2
          cursor-pointer
          select-none
        "
        v-for="item in displayValue"
        :key="item.value"
        @click.prevent="onRemoveValue(item.value)"
      >
        <span class="bg-active text-inactive">{{ item.label }}</span>
        <span class="bg-red-600 text-white hover:bg-red-900"
          ><icon face="mdiWindowClose"
        /></span>
      </div>
      <button
        v-if="!isSingle || displayValue.length === 0"
        class="
          rounded
          tag
          inline-flex
          items-center
          text-sm
          mx-2
          my-2
          cursor-pointer
          select-none
        "
        @click="showPicker"
      >
        <span class="bg-green-600 text-white"
          ><icon face="mdiPlusCircleOutline"
        /></span>
        <span class="bg-green-600 text-white">{{ $t('actions.add') }}</span>
      </button>
    </div>
    <div class="h-4 text-xs text-red-700">
      {{ error }}
    </div>
    <backdrop v-if="pickerShown" @close="hidePicker">
      <div class="absolute top-3 inset-x-14 flex items-center justify-center">
        <div class="flex -mx-1">
          <action-icon
            class="mx-1"
            variant="primary"
            face="mdiPencilPlus"
            small
            v-if="canAddItem"
            @click="onAddItem"
          />
          <div class="flex-1 mx-1">
            <field
              compact
              type="text"
              :placeholder="$t('general.search')"
              small
              v-model="search"
            />
          </div>
        </div>
      </div>
      <div class="absolute top-14 inset-x-2 bottom-2">
        <grid
          :name="gridName"
          class="bg-white"
          :heading="procHeaders"
          :items="collectionItems"
          :maximize="false"
          :value="value"
          @input="onItemSelect"
          :click-behaviour="isSingle ? 'model-single' : 'model-array'"
        />
      </div>
      <modal
        v-if="currentItem"
        :title="$t('actions.add')"
        @close="currentItem = null"
      >
        <form @submit.prevent="onSaveItem">
          <dynamic-field
            v-for="item in editHeaders"
            :key="item.id"
            :heading="item"
            :value="getCurrentItemValue(item)"
            @input="(event) => setCurrentItemValue(event, item)"
            @changed="clearCurrentError(item)"
            :error="currentItem.i18n(item.id)"
          ></dynamic-field>
          <div class="flex justify-end items-center mt-1 space-x-2">
            <action variant="link" @click="currentItem = null">{{
              $t('actions.cancel')
            }}</action>
            <action variant="primary" type="submit">{{
              $t('actions.save')
            }}</action>
          </div>
        </form>
      </modal>
    </backdrop>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Form, getProcHeaders, getEmptyHeaderValue } from '@/services'

export default {
  name: 'CollectionInput',
  props: {
    heading: Object,
    value: [Array, String],
    error: String
  },
  data() {
    return {
      pickerShown: false,
      search: '',
      currentItem: null
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user?.role === 'ADMIN',
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      loading: (state) => state.loading,
      loaded: (state) => state.loaded,
      zones: (state) => state.zones,
      types: (state) => state.types,
      contacts: (state) => state.contacts
    }),
    canAddItem() {
      if (this.isAdmin) {
        return true
      }
      return this.heading.source.name === 'contacts'
    },
    isSingle() {
      return this.heading.source.single === true
    },
    displayValue() {
      if (this.loading) {
        return []
      }
      if (!this.value) {
        return []
      }
      const dv = this.isSingle ? [this.value] : this.value

      const mainKey =
        this.procHeaders.find((item) => item.main)?.id || this.procHeaders[0].id

      const getCollectionValue = (value) => {
        const item = this.collection?.items?.find((item) => item._id === value)
        return item ? item[mainKey] : value
      }

      return dv.map((item) => ({
        value: item,
        label: getCollectionValue(item)
      }))
    },
    wId() {
      return this.heading.id
    },
    collection() {
      if (this.loading) {
        return null
      }
      return this.$store.state[this.heading.source.name]
    },
    procHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === this.heading.source.name
      )
      const _headers = this.headers?.items.find(
        (item) => item.name === this.heading.source.name
      )
      return getProcHeaders(_settings.items, _headers?.items)
    },
    editHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false)
    },
    gridHeaders() {
      return this.procHeaders.filter((h) => !!h.show)
    },
    collectionItems() {
      if (!this.collection?.items) {
        return []
      }
      const _items = this.collection.items.slice(0)
      const _search = this.search.trim().toLocaleLowerCase()
      if (!_search) {
        return _items
      }
      return _items.filter((item) => item.name.toLowerCase().includes(_search))
    },
    gridName() {
      return `${this.heading.id}:mod`
    }
  },
  methods: {
    showPicker() {
      this.pickerShown = true
    },
    hidePicker() {
      this.pickerShown = false
    },
    onRemoveValue(value) {
      if (this.isSingle) {
        return this.$emit('input', '')
      }
      const idx = this.value.findIndex((item) => item === value)
      if (idx >= 0) {
        const nv = this.value.slice(0)
        nv.splice(idx, 1)
        return this.$emit('input', nv)
      }
    },
    getCurrentItemValue(heading) {
      if (!this.currentItem) {
        return null
      }
      const data = this.currentItem.data
      const v = data[heading.id]
      return v ? JSON.parse(JSON.stringify(v)) : v
    },
    setCurrentItemValue(value, heading) {
      if (!this.currentItem) {
        return null
      }
      this.$set(this.currentItem, heading.id, value)
    },
    clearCurrentError(heading) {
      if (!this.currentItem) {
        return null
      }
      this.currentItem.clear(heading.id)
    },
    onItemSelect(value) {
      this.$emit('input', value)
    },
    onAddItem() {
      const data = this.editHeaders.reduce(
        (item, h) => ({
          ...item,
          [h.id]: getEmptyHeaderValue(h)
        }),
        {}
      )

      const validation = this.editHeaders.reduce(
        (validation, h) => ({
          ...validation,
          ...(h.req || h.main ? { [h.id]: ['required'] } : {})
        }),
        {}
      )

      this.currentItem = new Form(data, validation)
    },
    async onSaveItem() {
      if (!this.currentItem) {
        return false
      }

      if (!this.currentItem.validate()) {
        return false
      }

      const formData = this.currentItem.data

      const payload = this.editHeaders.reduce(
        (payload, h) => ({
          ...payload,
          [h.id]: formData[h.id]
        }),
        {}
      )

      const actionName = `${this.heading.source.name}/create`

      const result = await this.$store.dispatch(actionName, payload)
      this.currentItem = null

      this.$nextTick(() => {
        let newValue = null
        if (this.isSingle) {
          newValue = result._id
        } else {
          newValue =
            this.value && Array.isArray(this.value) ? [...this.value] : []
          newValue.push(result._id)
        }
        this.onItemSelect(newValue)
      })
    }
  }
}
</script>
