<template>
  <div
    class="
      bg-white bg-opacity-40
      backdrop-filter backdrop-blur-sm
      w-screen
      h-screen
      fixed
      inset-0
      flex flex-col
      items-center
      justify-center
      z-30
      cursor-wait
    "
  >
    <loading-icon class="h-1/6 max-h-12 text-blue-300" />
    <div class="relative mt-4 w-64" v-if="!loading">
      <div class="overflow-hidden h-4 flex rounded bg-blue-100">
        <div
          :style="{ width: `${percent}%` }"
          class="transition-all rounded bg-blue-500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
import { mapGetters, mapState, mapActions } from 'vuex'
import { settings as defaultSettings } from '@/config'

const _wait = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(true), 50)
  })

export default {
  name: 'InitSettings',
  components: { LoadingIcon },
  data() {
    return {
      done: 0
    }
  },
  computed: {
    ...mapGetters(['loading', 'loaded']),
    ...mapState({
      settings: (state) => state.settings
    }),
    total() {
      return Object.keys(defaultSettings).length
    },
    percent() {
      return (this.done * 100) / this.total
    }
  },
  mounted() {
    this.startProcess()
  },
  methods: {
    ...mapActions(['settings/create']),
    async startProcess() {
      if (this.loading) {
        await _wait()
        this.$nextTick(this.startProcess)
        return
      }

      const names = Object.keys(defaultSettings)
      for (let i = 0; i < names.length; i++) {
        await this.seedSetting(names[i])
        await _wait()
      }
      if (this.done === this.total) {
        this.$nextTick(() => {
          this.$emit('done')
        })
      }
    },
    async seedSetting(name) {
      const hasSetting = !this.settings?.items?.length
        ? false
        : this.settings.items.find((item) => item.name === name)
      if (!hasSetting) {
        const items = defaultSettings[name].map((item) => ({
          ...item,
          name: this.$t(item.name)
        }))
        await this['settings/create']({
          name,
          items
        })
      }
      this.done += 1
    }
  }
}
</script>
