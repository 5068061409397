<template>
  <page-hero>
    <form @submit.prevent="onLogin">
      <card class="w-96 max-w-sm" :title="$t('login.title')">
        <field
          type="email"
          :label="$t('email.label')"
          :placeholder="$t('email.placeholder')"
          :disabled="isBusy"
          v-model="form.email"
          @changed="form.clear('email')"
          :error="form.i18n('email')"
        />
        <field
          type="password"
          :label="$t('password.label')"
          :placeholder="$t('password.placeholder')"
          :disabled="isBusy"
          v-model="form.password"
          @changed="form.clear('password')"
          :error="form.i18n('password')"
        />
        <div class="flex flex-row justify-end -mx-2">
          <action
            type="submit"
            variant="primary"
            class="mx-2"
            :loading="isBusy"
            :disabled="isBusy"
            >{{ $t('login.button') }}</action
          >
        </div>
      </card>
      <div class="text-center mt-4">
        <span class="mt-4 px-4 py-1 bg-green-200 text-green-700 rounded text-xs"
          >v. {{ version }}</span
        >
      </div>
    </form>
  </page-hero>
</template>

<script>
import { storage, Form } from '@/services'

import pak from '../../package.json'

export default {
  data() {
    return {
      isBusy: false,
      message: null,
      form: new Form(
        { email: '', password: '' },
        {
          email: ['required', 'email'],
          password: ['required', 'min:6']
        }
      )
    }
  },
  methods: {
    async onLogin() {
      this.isBusy = true
      if (!this.form.validate()) {
        this.isBusy = false
        return false
      }
      if (!this.form.validate()) {
        return false
      }
      try {
        const response = await this.$http.post('/auth/login', this.form.data)
        storage.token = response.data.token
        const profile = await this.$http.get('/auth/profile', { refresh: true })
        this.$store.dispatch('login', profile.data.data)
        this.$router.push('/')
      } catch (err) {
        console.error('* Error while authenticating', err)
        this.$toast('danger', 'errors.invalid_credentials')
        this.isBusy = false
      }
    }
  },
  computed: {
    version() {
      return pak.version
    }
  }
}
</script>
