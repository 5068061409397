<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <input
        :type="wType"
        class="
          w-full
          rounded
          border border-gray-300
          block
          bg-white
          h-12
          px-4
          is-focusable
        "
        :id="wId"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    heading: Object,
    value: String,
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    },
    wType() {
      switch (this.heading.type) {
        case 'N':
          return 'number'
        case 'D':
          return 'date'
        default:
          return 'text'
      }
    }
  }
}
</script>
