export default [
  '#4169E1', // royalblue
  '#FF4500', // orangered
  '#FFA500', // orange
  '#228B22', // forestgreen
  '#8B008B', // darkmagenta
  '#20B2AA', // lightseagreen
  '#CD5C5C', // indianred
  '#6B8E23' // olivedrab
]
