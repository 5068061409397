import { default as TextInput } from './TextInput'
import { default as NumberInput } from './NumberInput'
import { default as ListInput } from './ListInput'
import { default as MultipleListInput } from './MultipleListInput'
import { default as TextareaInput } from './TextareaInput'
import { default as WarningInput } from './WarningInput'
import { default as BoolInput } from './BoolInput'
import { default as ArrayInput } from './ArrayInput'
import { default as GeoInput } from './GeoInput'
import { default as CollectionInput } from './CollectionInput'
import { default as PriceInput } from './PriceInput'
import { default as MediaInput } from './MediaInput'

export default {
  S: TextInput,
  N: NumberInput,
  D: TextInput,
  L: ListInput,
  C: CollectionInput,
  ML: MultipleListInput,
  T: TextareaInput,
  A: ArrayInput,
  W: WarningInput,
  G: GeoInput,
  P: PriceInput,
  R: PriceInput,
  M: MediaInput,
  B: BoolInput
}
