const nf = (value) => parseFloat(value).toFixed(2)

export default {
  type: 'T',
  render: (value) =>
    value && value.lat && value.lng ? `${nf(value.lat)},${nf(value.lng)}` : '-',
  compare: (a, b) =>
    a && a.lng && b && b.lng
      ? parseFloat(a.lng) - parseFloat(b.lng)
      : a && a.lng
      ? 1
      : -1
}
