<template>
  <component :is="as" class="inline-block" :class="vClass"><slot /></component>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    size: String,
    as: { type: String, default: 'span' },
    variant: String,
    inverted: { type: Boolean, default: false }
  },
  computed: {
    vClass() {
      const classes = []
      switch (this.size) {
        case 'xs':
          classes.push('text-xs rounded-sm p-1')
          break
        case 'sm':
          classes.push('text-xs rounded-sm px-2 py-1')
          break
        case 'lg':
          classes.push('rounded-md px-4 py-2')
          break
        default:
          classes.push('text-sm rounded px-2 py-1')
      }
      switch (this.variant) {
        case 'danger':
        case 'error':
        case 'red':
          classes.push(
            this.inverted ? 'bg-red-800 text-red-50' : 'bg-red-50 text-red-800'
          )
          break
        case 'success':
        case 'green':
          classes.push(
            this.inverted
              ? 'bg-green-800 text-green-50'
              : 'bg-green-50 text-green-800'
          )
          break
        case 'gray':
          classes.push(
            this.inverted
              ? 'bg-gray-600 text-gray-50'
              : 'bg-gray-50 text-gray-800'
          )
          break
        case 'disabled':
          classes.push('bg-gray-50 text-gray-300')
          break
        case 'warning':
        case 'warn':
        case 'yellow':
          classes.push(
            this.inverted
              ? 'bg-yellow-800 text-yellow-50'
              : 'bg-yellow-50 text-yellow-800'
          )
          break
        case 'indigo':
          classes.push(
            this.inverted
              ? 'bg-indigo-800 text-indigo-50'
              : 'bg-indigo-50 text-indigo-800'
          )
          break
        case 'purple':
          classes.push(
            this.inverted
              ? 'text-purple-50 bg-purple-800'
              : 'bg-purple-50 text-purple-800'
          )
          break
        case 'pink':
          classes.push(
            this.inverted
              ? 'text-pink-50 bg-pink-800'
              : 'bg-pink-50 text-pink-800'
          )
          break
        default:
          classes.push(
            this.inverted
              ? 'text-blue-50 bg-blue-800'
              : 'bg-blue-50 text-blue-800'
          )
      }

      return classes.join(' ')
    }
  }
}
</script>
