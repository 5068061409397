<template>
  <main class="">
    <navigation v-if="logged" :user="user">
      <slot name="navigation" />
    </navigation>
    <toast />
    <section class="px-2 mt-16">
      <slot />
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from '../Navigation'
import Toast from '../Toast.vue'

export default {
  name: 'Page',
  components: { Navigation, Toast },
  computed: {
    ...mapGetters(['logged', 'user'])
  }
}
</script>
