import api from '@/services/api'

const allowedIndicators = ['loading', 'updating', 'loaded']

const state = () => ({
  loading: false,
  updating: false,
  loaded: false,
  items: []
})

const getters = {
  isLoading: (state) => state.loading,
  isUpdating: (state) => state.updating,
  isBusy: (state) => state.loading || state.updating,
  isLoaded: (state) => state.loaded,
  items: (state) => state.items
}

const mutations = {
  indicators(state, indicators) {
    Object.keys(indicators)
      .filter((indicator) => allowedIndicators.includes(indicator))
      .forEach((indicator) => (state[indicator] = !!indicators[indicator]))
  },
  store(state, items) {
    state.items = [...items]
  },
  insert(state, item) {
    if (Array.isArray(item)) {
      state.items = [...state.items, ...item]
    } else {
      const newItems = state.items.slice(0)
      newItems.push(item)
      state.items = newItems
    }
  },
  update(state, item) {
    const idx = state.items.findIndex((found) => found._id === item._id)
    if (idx < 0) {
      console.error('Cannot update item in state', item)
      return
    }
    const newItems = state.items.slice(0)
    newItems.splice(idx, 1, item)
    state.items = newItems
  },
  remove(state, _id) {
    const idx = state.items.findIndex((found) => found._id === _id)
    if (idx < 0) {
      console.error('Could not remove from state', _id)
      return
    }
    const newItems = state.items.slice(0)
    newItems.splice(idx, 1)
    state.items = newItems
  }
}

const getActions = (name) => ({
  async ping() {
    console.log('Ping', name)
  },
  async load({ commit }) {
    commit('indicators', { loading: true })
    const all = await api.list(name, {})
    commit('store', all || [])
    commit('indicators', { loading: false, loaded: true })
    return all || []
  },
  async create({ commit }, item) {
    commit('indicators', { updating: true })
    const neuron = await api.create(name, item)
    if (neuron) {
      commit('insert', neuron)
    }
    commit('indicators', { updating: false })
    return neuron
  },
  async update({ commit }, { _id, ...data }) {
    commit('indicators', { updating: true })
    const nerve = await api.update(name, _id, data)
    if (nerve) {
      commit('update', { _id, ...nerve })
    }
    commit('indicators', { updating: false })
    return nerve
  },
  async destroy({ commit }, { _id }) {
    commit('indicators', { updating: true })
    const result = await api.destroy(name, _id, {})
    if (result) {
      commit('remove', _id)
    }
    commit('indicators', { updating: false })
    return result
  }
})

export default (collectionName) => ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions: getActions(collectionName)
})
