<template>
  <div class="fixed right-4 bottom-4 w-80 z-50">
    <div
      v-for="message in messages"
      :key="message.id"
      class="p-4 border-l-8 rounded mt-4 flex"
      :class="{
        'border-red-600 text-red-600 bg-red-100':
          message.type === 'danger' || message.type === 'error',
        'border-green-700 text-green-700 bg-green-100':
          message.type === 'success'
      }"
    >
      <icon face="mdiAlert" />
      <span class="text-xs pl-2">{{ $t(message.body, message.args) }}</span>
    </div>
  </div>
</template>

<script>
class ToastMessage {
  constructor(type, body, { timeout, onExpire }) {
    this.type = type
    if (body.text && body.args) {
      this.body = body.text
      this.args = body.args
    } else {
      this.body = body
      this.args = undefined
    }
    this.id = `toast-message-${Math.floor(100000 * Math.random())}`
    this.onExpire = onExpire
    this.timeout = setTimeout(() => {
      this.onExpire(this)
    }, timeout)
  }
}

export default {
  props: {
    timeout: {
      type: Number,
      default: 1500
    }
  },
  data() {
    return {
      tk: null,
      messages: []
    }
  },
  mounted() {
    this.$bus.$on('toast', this.pushMessage)
    this.startCounting()
  },
  beforeDestroy() {
    this.$bus.$off('toast', this.pushMessage)
    this.stopCounting()
  },
  methods: {
    pushMessage({ type, message }) {
      this.messages.push(
        new ToastMessage(type, message, {
          timeout: this.timeout,
          onExpire: this.onExpire
        })
      )
      if (this.messages.length > 4) {
        this.messages.splice(0, 1)
      }
      this.startCounting()
      return true
    },
    onExpire(message) {
      const idx = this.messages.findIndex((item) => item.id === message.id)
      this.removeMessage(idx)
      this.startCounting()
    },
    removeMessage(idx) {
      if (idx >= 0 && idx < this.messages.length) {
        this.messages.splice(idx, 1)
        if (this.messages.length > 0) {
          this.startCounting()
        }
      }
    },
    startCounting() {
      this.stopCounting()
      this.tk = setTimeout(() => {
        this.removeMessage(0)
      }, this.timeout)
    },
    stopCounting() {
      clearTimeout(this.tk)
    }
  }
}
</script>
