<template>
  <div class="relative">
    <div
      v-if="isUploading"
      class="
        absolute
        inset-0
        bg-white bg-opacity-75
        flex
        items-center
        justify-center
        z-50
        text-blue-600
      "
    >
      <icon face="mdiRefresh" size="4rem" spin />
    </div>
    <div
      :class="{
        'overflow-y-auto': !currentImage,
        'overflow-hidden': currentImage
      }"
      :style="computedStyle"
    >
      <div class="flex flex-row flex-wrap">
        <div
          class="rounded w-1/2 sm:w-1/4 lg:w-1/6"
          v-for="img in thumbs"
          :key="img._key"
        >
          <div
            class="
              my-1
              mx-1
              border border-gray-200
              shadow-sm
              pb-4
              bg-white
              hover:border-active
            "
            :class="{
              'ring ring-blue-300 bg-blue-100': isSelected(img)
            }"
          >
            <button
              type="button"
              class="w-full square relative"
              @click="onClick(img)"
            >
              <figure
                v-if="img._isImage"
                class="
                  absolute
                  inset-4
                  no-pattern-bg
                  flex
                  items-center
                  justify-center
                "
              >
                <img
                  :src="img._thumb"
                  class="max-w-full max-h-full"
                  :id="img._key"
                  @load="onImageLoad(img)"
                />
              </figure>
              <div
                v-else
                class="
                  absolute
                  inset-4
                  bg-white
                  flex
                  items-center
                  justify-center
                "
              >
                <img :src="fileIcon" class="w-8 h-auto" :id="img._key" />
              </div>
            </button>
            <div class="px-4 flex flex-row items-center -mt-4">
              <p
                class="
                  flex-1
                  text-xs text-gray-700
                  max-w-full
                  font-semibold
                  whitespace-nowrap
                  overflow-hidden overflow-ellipsis
                "
              >
                {{ img.original }}
              </p>
              <chip size="xs" variant="blue" class="uppercase">{{
                getMime(img)
              }}</chip>
            </div>
            <div
              class="
                px-4
                text-xs
                whitespace-nowrap
                overflow-hidden overflow-ellipsis
              "
            >
              <span class="text-blue-700" v-if="imageDimensions[img._key]">
                {{ imageDimensions[img._key].width }} &times;
                {{ imageDimensions[img._key].height }}px
              </span>
              <span class="text-gray-400 ml-1"
                >{{ img._niceSize.value }}{{ img._niceSize.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute inset-0 flex flex-col md:flex-row bg-white"
      v-if="currentImage"
    >
      <button
        @click="currentImage = null"
        :disabled="isBusy"
        type="button"
        class="
          absolute
          right-2
          top-2
          w-10
          h-10
          text-2xl
          flex
          items-center
          justify-center
          cursor-pointer
          bg-transparent
          text-gray-600
          hover:text-black
          bg-white
          rounded-sm
          shadow
          z-50
        "
      >
        <icon face="mdiWindowClose" />
      </button>
      <div class="w-full flex-1 p-1 bg-gray-100" v-if="currentImage._isImage">
        <div class="no-pattern-bg h-full w-full relative">
          <div
            class="
              absolute
              bg-no-repeat bg-center bg-contain
              inset-0
              md:inset-2
            "
            :style="{
              backgroundImage: `url(${currentImage._thumb})`
            }"
          ></div>
        </div>
      </div>
      <div
        class="w-full p-4 bg-gray-100 flex-shrink-0"
        :class="{
          'md:w-1/3': currentImage._isImage
        }"
      >
        <div class="h-full relative">
          <div class="overflow-y-auto">
            <div v-for="item in currentImageDetails" :key="item.id">
              <label class="font-bold text-sm">{{ $t(item.label) }}</label>
              <p
                class="
                  text-xs
                  whitespace-nowrap
                  max-w-full
                  overflow-hidden overflow-ellipsis
                "
                :title="item.value"
              >
                {{ item.value }}
              </p>
            </div>
            <div class="mb-2">
              <label class="font-bold text-sm">{{ $t('media.url') }}</label>
              <a
                target="_blank"
                :download="currentImage.original"
                class="
                  text-blue-500
                  whitespace-nowrap
                  max-w-full
                  overflow-hidden overflow-ellipsis
                  block
                  hover:underline
                  text-xs
                "
                :href="currentImage._thumb"
                :title="currentImage._thumb"
                >{{ currentImage._thumb }}</a
              >
            </div>
          </div>
          <hr />
          <div class="flex-shrink-0 flex mt-1">
            <confirm
              icons-only
              @click="onImageDelete"
              class="mt-4 md:mt-0"
              :disabled="isBusy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { getMediaURL, getNiceSize } from '@/services'

import fileIcon from '@/assets/file-icon.png'

export default {
  name: 'MediaGrid',
  props: {
    search: { type: String, default: '' },
    pick: { type: String, default: 'details' },
    selected: { type: Array, default: () => [] },
    filter: { type: String, default: 'all' }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) => state.images.loading || state.images.updating,
      isAdmin: (state) => state.user?.role === 'ADMIN',
      images: (state) => state.images
    }),
    fileIcon() {
      return fileIcon
    },
    filteredByType() {
      if (this.filter === 'all') {
        return this.images?.items || []
      }
      const _filter =
        this.filter === 'doc'
          ? (img) => !img.mime.startsWith('image/')
          : (img) => img.mime.startsWith('image/')
      return this.images?.items?.filter(_filter)
    },
    filteredImages() {
      const ordered = this.filteredByType
        .slice(0)
        .sort((a, b) => b.createdAt - a.createdAt)

      const _search = this.search.trim().toLowerCase()
      if (!_search) {
        return ordered
      }
      return ordered.filter((img) => {
        return img.name.toLowerCase().includes(_search)
      })
    },
    thumbs() {
      return this.filteredImages.slice(0).map((img) => ({
        ...img,
        _thumb: getMediaURL(img),
        _niceSize: getNiceSize(img, 0),
        _key: `image-${img._id}`,
        _isImage: img.mime.startsWith('image/')
      }))
    },
    currentImageDetails() {
      if (!this.currentImage) {
        return []
      }
      return [
        { id: 'name', label: 'media.name', value: this.currentImage.original },
        { id: 'mime', label: 'media.mime', value: this.currentImage.mime },
        // {
        //   id: 'storage',
        //   label: 'media.storage',
        //   value: this.currentImage.storageFile
        // },
        {
          id: 'load',
          label: 'media.load',
          value: this.imageDimensions[this.currentImage._key]
            ? `${this.imageDimensions[this.currentImage._key].width} × ${
                this.imageDimensions[this.currentImage._key].height
              } px`
            : '-'
        },
        {
          id: 'size',
          label: 'media.size',
          value: `${this.currentImage._niceSize.value} ${
            this.currentImage._niceSize.unit
          } ${
            this.currentImage._niceSize.unit !== 'b'
              ? ` (${this.currentImage.size}b)`
              : ''
          }`
        },
        {
          id: 'created',
          label: 'general.createdAt',
          value: moment.utc(this.currentImage.createdAt).local().format('LLLL')
        }
      ]
    }
  },
  data() {
    return {
      imageDimensions: {},
      currentImage: null,
      computedStyle: {},
      isUploading: false
    }
  },
  mounted() {
    this.$bus.$on('images-upload', this.onUploadImages)
    this.$bus.$on('begin-images-upload', this.beginUpload)
    const rect = this.$el.getBoundingClientRect()
    this.computedStyle = {
      height: `calc(100vh - ${rect.top}px)`
    }
  },
  beforeDestroy() {
    this.$bus.$off('images-upload', this.onUploadImages)
    this.$bus.$off('begin-images-upload', this.beginUpload)
  },
  methods: {
    ...mapActions(['images/create', 'images/destroy']),
    getMime({ mime, extension }) {
      const _mimes = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp'
      }

      return _mimes[mime] || extension || '?'
    },
    beginUpload() {
      this.isUploading = true
    },
    async onUploadImages(data) {
      try {
        this.isUploading = true
        const promises = data.map(
          (upload) => async () => this['images/create'](upload)
        )
        await Promise.all(promises.map((p) => p()))
      } catch (err) {
        console.error('Error while uploading', err)
      } finally {
        this.isUploading = false
      }
    },
    onImageLoad(img) {
      const el = document.getElementById(img._key)
      if (el && el.naturalHeight && el.naturalWidth) {
        this.$set(this.imageDimensions, img._key, {
          width: el.naturalWidth,
          height: el.naturalHeight
        })
      }
    },
    isSelected({ _id }) {
      return this.selected.includes(_id)
    },
    onClick(img) {
      let idx, nv
      switch (this.pick) {
        case 'model':
          nv = this.selected.slice(0)
          idx = nv.findIndex((item) => item === img._id)
          if (idx >= 0) {
            nv.splice(idx, 1)
          } else {
            nv.push(img._id)
          }
          return this.$emit(
            'select',
            nv,
            this.images.items.filter((img) => nv.includes(img._id))
          )
        default:
          this.currentImage = img
      }
    },

    async onImageDelete(img) {
      const _img = img || this.currentImage
      if (!_img) {
        return
      }
      await this['images/destroy'](_img)
      this.currentImage = null
    }
  }
}
</script>
