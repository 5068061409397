var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"relative overflow-x-auto overflow-y-auto shadow-sm",style:(_vm.computedStyle)},[_c('table',{ref:"table",staticClass:"gi-table"},[_c('thead',[_c('tr',_vm._l((_vm.wHeading),function(h){return _c('th',{key:h.id,staticClass:"select-none relative",class:{
            'bg-green-500': _vm.variant === 'estates' && _vm.sortHeadingKey !== h.id,
            'bg-green-700': _vm.variant === 'estates' && _vm.sortHeadingKey === h.id,
            'bg-pink-500': _vm.variant === 'contacts' && _vm.sortHeadingKey !== h.id,
            'bg-pink-700': _vm.variant === 'contacts' && _vm.sortHeadingKey === h.id,
            'bg-yellow-500':
              _vm.variant === 'archives' && _vm.sortHeadingKey !== h.id,
            'bg-yellow-600':
              _vm.variant === 'archives' && _vm.sortHeadingKey === h.id,
            'bg-yellow-700': _vm.variant === 'media' && _vm.sortHeadingKey === h.id,
            'bg-gray-500': _vm.variant === 'default' && _vm.sortHeadingKey !== h.id,
            'bg-gray-700': _vm.variant === 'default' && _vm.sortHeadingKey === h.id
          },attrs:{"title":h.name},on:{"click":function($event){return _vm.toggleSort(h)}}},[_c('span',{staticClass:"gi-grid-icon"},[_c('icon',{attrs:{"face":_vm.getSortingIcon(h)}})],1),_c('span',{staticClass:"gi-grid-label"},[_vm._v(_vm._s(h.name))])])}),0)]),_c('tbody',_vm._l((_vm.sortedItems),function(item){return _c('tr',{key:item._id,class:{ active: _vm.isActive(item) },on:{"click":function (event) { return _vm.onRowClick(item, event); }}},_vm._l((_vm.wHeading),function(h){return _c('td',{key:h.id},[_vm._v(" "+_vm._s(item.__render[h.id])+" ")])}),0)}),0)]),(_vm.isRenderBusy)?_c('div',{staticClass:"\n      absolute\n      inset-x-0\n      top-10\n      bottom-0\n      backdrop-filter backdrop-blur-sm\n      bg-white bg-opacity-40\n      flex\n      items-center\n      justify-center\n      text-3xl text-gray-400\n    "},[_c('icon',{attrs:{"face":"mdiRefresh","spin":""}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }