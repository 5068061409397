import { i18n } from '@/services'

import { validate } from './validator'
export class Form {
  constructor(initialValues, rules) {
    Object.keys(initialValues).forEach((key) => {
      this[key] = initialValues[key]
    })
    this.errors = {}
    this._rules = rules
  }

  validate() {
    this.errors = validate(this._rules, this.data)
    return Object.keys(this.errors).length === 0
  }

  i18n(name) {
    const _e = this.errors[name]
    if (!_e) {
      return null
    }
    let [directive, args] = _e.split(':')
    if (args) {
      args = args.split(',')
    } else {
      args = []
    }
    return i18n.t(`validation.${directive}`, args)
  }

  clear(name) {
    if (name) {
      const _errors = { ...this.errors }
      delete _errors[name]
      this.errors = { ..._errors }
    }
    this.errors = {}
  }

  get data() {
    const data = { ...this }
    delete data.errors
    delete data._rules
    return data
  }
}
