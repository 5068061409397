export default [
  {
    id: 'name',
    name: 'types.name',
    show: true,
    fill: true,
    ro: true,
    main: true,
    type: 'S'
  },
  {
    id: 'createdAt',
    name: 'general.createdAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  },
  {
    id: 'updatedAt',
    name: 'general.updatedAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  }
]
