import axios from 'axios'
import storage from './storage'
import bus from './bus'

const http = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL || 'http://localhost:5000/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use((config) => {
  const token = storage.token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  bus.$emit('network-status', true)
  return config
}, Promise.reject)

http.interceptors.response.use(
  (response) => {
    bus.$emit('network-status', false)
    return response
  },
  (err) => {
    bus.$emit('network-status', false)
    return Promise.reject(err)
  }
)

export default http
