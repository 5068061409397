import Vue from 'vue'
import App from './App.vue'
import { router, store, storage, bus, http, i18n, api } from './services'

import './assets/tailwind.css'
import './components/_globals'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.prototype.$toast = (type, message) => {
  bus.$emit('toast', { type, message })
}

Vue.prototype.$bus = bus
Vue.prototype.$http = http
Vue.prototype.$api = api

const init = async () => {
  if (!storage.token) {
    boot()
    return
  }

  try {
    const response = await http.get('/auth/profile', { refresh: true })
    store.dispatch('login', response.data.data)
  } catch (err) {
    storage.token = null
    console.error('Token error', err)
  } finally {
    boot()
  }
}

const boot = () =>
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')

init()
