const PERSIST_KEY = 'grid.cfg'
const STORAGE = window.localStorage

const has = (name) => {
  const m = STORAGE.getItem(`${PERSIST_KEY}.${name}`)
  if (!m) {
    return false
  }
  const mo = JSON.parse(m)
  return !!mo
}

const load = (name, defaultValue = {}) => {
  const m = STORAGE.getItem(`${PERSIST_KEY}.${name}`)
  if (!m) {
    return defaultValue
  }
  const mo = JSON.parse(m)
  return mo || defaultValue
}

const save = (name, value) => {
  STORAGE.setItem(`${PERSIST_KEY}.${name}`, JSON.stringify(value))
  return value
}

const clear = (name) => {
  STORAGE.removeItem(`${PERSIST_KEY}.${name}`)
}

export default {
  has,
  clear,
  load,
  save
}
