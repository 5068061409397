const _hash = (array, by) =>
  array.reduce(
    (mapped, item, idx) => ({
      ...mapped,
      [item[by]]: { ...item, __idx: idx }
    }),
    {}
  )

export const getProcHeaders = (settings, headers) => {
  const _headers =
    headers && Array.isArray(headers) && headers.length > 0
      ? headers
      : extractHeaders(settings)

  const _flatten = _hash(_headers, 'id')

  const _compare = (a, b) => {
    const idxA = _flatten?.[a.id]?.__idx
    const idxB = _flatten?.[b.id]?.__idx
    if (idxA === undefined) {
      return idxB === undefined ? 0 : 1
    }
    if (idxB === undefined) {
      return -1
    }
    return idxA - idxB
  }

  const _mapper = (item) => ({
    ...item,
    show: _flatten[item.id] !== undefined ? _flatten[item.id].show : item.show
  })

  const ps = settings.map(_mapper).sort(_compare)

  return ps
}

export const extractHeaders = (procHeaders) =>
  procHeaders.slice(0).map(({ id, show }) => ({
    id,
    show: !!show
  }))

export const getEmptyHeaderValue = ({ type, source }) => {
  switch (type) {
    case 'L':
      return source?.single === false ? [] : ''
    case 'C':
      return source?.single ? null : []
    case 'A':
    case 'M':
      return []
    case 'G':
      return {}
    case 'P':
    case 'R':
      return { p: '', u: 'A', i: 'M' }
    case 'B':
    case 'N':
    case 'W':
    default:
      return undefined
  }
}
