export const actionVariants = {
  outline: 'border border-gray-600 text-gray-700',
  link: 'border border-transparent bg-transparent text-blue-500 hover:text-blue-700',
  primary:
    'border border-blue-900 text-blue-100 bg-blue-700 hover:text-white hover:shadow-md active:shadow-none',
  danger:
    'border border-red-900 text-red-100 bg-red-700 hover:text-white hover:shadow-md active:shadow-none',
  success:
    'border border-green-900 text-green-100 bg-green-700 hover:text-white hover:shadow-md active:shadow-none'
}
