import { default as timestamp } from './timestamp'
import { default as list } from './list'
import { default as switcher } from './switcher'
import { default as lineArray } from './lineArray'
import { default as collection } from './collection'
import { default as price } from './price'
import { default as media } from './media'
import { default as text } from './text'
import { default as geo } from './geo'
import { default as number } from './number'

export * from './render'

const _default = {
  render: (value) => value,
  compare: (a, b) => {
    return a ? a.localeCompare(b) : -1
  }
}

export const gridHelpers = {
  U: timestamp,
  L: list,
  A: lineArray,
  W: switcher,
  B: switcher,
  C: collection,
  P: price,
  R: price,
  M: media,
  T: text,
  N: number,
  G: geo,
  S: _default
}
