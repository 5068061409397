<template>
  <div
    class="relative overflow-x-auto overflow-y-auto shadow-sm"
    ref="wrapper"
    :style="computedStyle"
  >
    <table class="gi-table" ref="table">
      <thead>
        <tr>
          <th
            v-for="h in wHeading"
            :key="h.id"
            class="select-none relative"
            :title="h.name"
            :class="{
              'bg-green-500': variant === 'estates' && sortHeadingKey !== h.id,
              'bg-green-700': variant === 'estates' && sortHeadingKey === h.id,
              'bg-pink-500': variant === 'contacts' && sortHeadingKey !== h.id,
              'bg-pink-700': variant === 'contacts' && sortHeadingKey === h.id,
              'bg-yellow-500':
                variant === 'archives' && sortHeadingKey !== h.id,
              'bg-yellow-600':
                variant === 'archives' && sortHeadingKey === h.id,
              'bg-yellow-700': variant === 'media' && sortHeadingKey === h.id,
              'bg-gray-500': variant === 'default' && sortHeadingKey !== h.id,
              'bg-gray-700': variant === 'default' && sortHeadingKey === h.id
            }"
            @click="toggleSort(h)"
          >
            <span class="gi-grid-icon"><icon :face="getSortingIcon(h)" /></span>
            <span class="gi-grid-label">{{ h.name }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in sortedItems"
          :key="item._id"
          :class="{ active: isActive(item) }"
          @click="(event) => onRowClick(item, event)"
        >
          <td v-for="h in wHeading" :key="h.id">
            {{ item.__render[h.id] }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="
        absolute
        inset-x-0
        top-10
        bottom-0
        backdrop-filter backdrop-blur-sm
        bg-white bg-opacity-40
        flex
        items-center
        justify-center
        text-3xl text-gray-400
      "
      v-if="isRenderBusy"
    >
      <icon face="mdiRefresh" spin />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { gridHelpers, getItemsRender } from '@/services/grid'
import persistConfig from '@/services/grid/persistConfig'

export default {
  name: 'Grid',
  props: {
    heading: Array,
    items: Array,
    variant: { type: String, default: 'default' },
    maximize: { type: Boolean, default: true },
    value: [String, Array],
    clickBehaviour: String,
    search: String,
    name: String,
    persist: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      computedStyle: {},
      sortHeadingKey: null,
      sortDir: -1,
      currentRowId: null,
      gridItems: [],
      isRenderBusy: false
    }
  },
  mounted() {
    if (this.maximize) {
      const rect = this.$el.getBoundingClientRect()
      this.computedStyle = {
        maxHeight: `calc(100vh - ${rect.top + window.pageYOffset + 12}px)`,
        minHeight: '8rem'
      }
    } else {
      this.computedStyle = { height: '100%' }
    }

    this.getRendered()

    this.loadConfig()
  },
  watch: {
    persist() {
      this.$nextTick(this.saveConfig)
    },
    loaded() {
      this.getRendered()
    },
    items() {
      this.getRendered()
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      settings: (state) => state.settings,
      zones: (state) => state.zones,
      contacts: (state) => state.contacts,
      types: (state) => state.types
    }),
    wHeading() {
      if (!this.heading) {
        return []
      }
      return this.heading.slice(0).filter((h) => !!h.show)
    },
    sortedItems() {
      if (!this.loaded) {
        return []
      }
      if (!this.sortHeadingKey) {
        return this.filteredItems
      }
      const sortHeader = this.wHeading.find(
        (item) => item.id === this.sortHeadingKey
      )
      if (!sortHeader) {
        return this.filteredItems
      }
      const gh = gridHelpers[sortHeader.type]
      if (!gh?.compare) {
        return this.filteredItems
      }

      const hid = sortHeader.sid ? sortHeader.sid : sortHeader.id

      if (!this.filteredItems) {
        return []
      }

      return this.filteredItems.slice(0).sort(
        (a, b) =>
          this.sortDir *
          gh.compare(a[hid], b[hid], {
            a,
            b,
            rendered: {
              a: a.__render[sortHeader.id],
              b: b.__render[sortHeader.id]
            },
            heading: sortHeader,
            collections: {
              zones: this.zones,
              contacts: this.contacts,
              types: this.types
            },
            mainCollectionDisplay: this.mainCollectionDisplay
          })
      )
    },
    filteredItems() {
      if (!this.loaded) {
        return []
      }
      if (!this.search) {
        return this.gridItems
      }

      const _search = this.search.trim().toLowerCase()
      return this.gridItems.filter((item) =>
        Object.values(item.__render).some((rendered) =>
          `${rendered}`.toLowerCase().includes(_search)
        )
      )
    },
    mainCollectionDisplay() {
      if (!this.loaded) {
        return null
      }
      const _collections = ['zones', 'contacts', 'types']
      return _collections.reduce(
        (display, item) => ({
          ...display,
          [item]: this.settings.items
            .find((found) => found.name === item)
            ?.items.find((found) => found.main)?.id
        }),
        {}
      )
    }
  },
  methods: {
    async getRendered() {
      this.isRenderBusy = true
      const rendered = await getItemsRender(
        this.items,
        this.wHeading,
        this.renderCell
      )
      this.gridItems = rendered
      this.isRenderBusy = false
    },
    isActive(item) {
      switch (this.clickBehaviour) {
        case 'model-single':
          return item._id === this.value
        case 'model-array':
          return this.value.includes(item._id)
        default:
          return item._id === this.currentRowId
      }
    },
    getSortingIcon(h) {
      if (this.sortHeadingKey === h.id) {
        return this.sortDir === 1 ? 'mdiMenuDown' : 'mdiMenuUp'
      }
      return 'mdiMenuSwap'
    },
    toggleSort(h) {
      if (this.sortHeadingKey === h.id) {
        this.sortDir = -1 * this.sortDir
      } else {
        this.sortHeadingKey = h.id
        this.sortDir = 1
      }
      this.$nextTick(this.saveConfig)
    },

    renderCell(heading, item) {
      if (!this.loaded) {
        return '?'
      }
      if (!gridHelpers[heading.type]) {
        return item[heading.id]
      }
      const hid = heading.sid ? heading.sid : heading.id
      if (heading.type === 'T' && heading.full) {
        return item[hid]
      }

      const text = gridHelpers[heading.type].render(item[hid], {
        item,
        heading,
        collections: {
          zones: this.zones,
          contacts: this.contacts,
          types: this.types
        },
        mainCollectionDisplay: this.mainCollectionDisplay
      })
      return text
    },
    onRowClick(item) {
      let idx, nv
      switch (this.clickBehaviour) {
        case 'model-single':
          if (this.currentRowId === item._id) {
            this.$emit('input', '')
            this.currentRowId = ''
          } else {
            this.currentRowId = item._id
            this.$emit('input', item._id)
          }
          break
        case 'model-array':
          idx = this.value.findIndex((v) => v === item._id)
          nv = this.value.slice(0)
          if (idx >= 0) {
            nv.splice(idx, 1)
          } else {
            nv.push(item._id)
          }
          return this.$emit('input', nv)
        default:
          if (this.currentRowId === item._id) {
            this.$emit('clicked', item)
          }
          this.currentRowId = item._id
          this.$nextTick(this.saveConfig)
      }
    },
    loadConfig() {
      if (!this.name) {
        return
      }
      const config = persistConfig.load(this.name, {
        o: { h: null, d: -1 },
        c: null
      })
      this.sortHeadingKey = config?.o?.h ?? null
      this.sortDir = config?.o?.d ?? -1
      this.currentRowId = config?.c
    },
    saveConfig() {
      if (!this.name) {
        return
      }
      persistConfig.save(this.name, {
        o: { h: this.sortHeadingKey, d: this.sortDir },
        c: this.currentRowId,
        ...(this.persist ? this.persist : {})
      })
    }
  }
}
</script>
