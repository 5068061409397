<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <div
        class="
          w-full
          flex flex-row flex-wrap
          bg-white
          m-0
          border border-gray-300
          rounded
          pt-4
          max-h-40
        "
      >
        <checkbox
          v-for="item in options"
          @input="(checked) => toggle(item, checked)"
          :key="item.id || item.value"
          :value="value.includes(item.value)"
          :label="item.label"
          class="mx-4"
        />
      </div>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleListInput',
  props: {
    heading: Object,
    value: Array,
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    },
    options() {
      return this.heading.source.options.map((opt) => ({
        id: opt.id || opt.value,
        value: opt.value,
        label: this.$t(opt.label)
      }))
    }
  },
  methods: {
    toggle(item, isIncluded) {
      if (isIncluded) {
        this.$emit('input', [...this.value, item.value])
        return
      }
      const idx = this.value.findIndex((v) => v === item.value)
      if (idx >= 0) {
        const _values = this.value.slice(0)
        _values.splice(idx, 1)
        this.$emit('input', _values)
      }
    }
  }
}
</script>
