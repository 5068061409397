export default [
  {
    id: 'title',
    name: 'estates.title',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    type: 'S'
  },
  {
    id: 'address',
    name: 'estates.address',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    full: true,
    type: 'T'
  },
  {
    id: 'transaction',
    name: 'estates.transaction',
    show: true,
    fill: true,
    ro: true,
    req: true,
    tag: false,
    type: 'L',
    source: {
      single: false,
      options: [
        { value: 'R', label: 'estates.rental' },
        { value: 'S', label: 'estates.sale' }
      ]
    }
  },
  {
    id: 'location',
    name: 'estates.location',
    show: false,
    fill: true,
    ro: true,
    req: false,
    tag: false,
    type: 'G'
  },
  {
    id: 'zoneId',
    name: 'estates.zones',
    show: true,
    fill: true,
    req: true,
    ro: true,
    tag: false,
    type: 'C',
    source: { single: false, name: 'zones', label: 'zones.nav' }
  },
  {
    id: 'priceSale',
    name: 'estates.priceSale',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    type: 'P'
  },
  {
    id: 'priceRent',
    name: 'estates.priceRent',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    type: 'R'
  },
  {
    id: 'typeId',
    name: 'estates.type',
    show: true,
    fill: true,
    req: true,
    ro: true,
    tag: false,
    type: 'C', // collection
    source: { single: false, name: 'types', label: 'types.nav' }
  },
  {
    id: 'contactId',
    name: 'estates.contacts',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    type: 'C', // collection
    source: { single: true, name: 'contacts', label: 'contacts.nav' }
  },
  {
    id: 'status',
    name: 'estates.status',
    show: true,
    fill: true,
    ro: true,
    tag: false,
    req: true,
    type: 'L',
    source: {
      single: true,
      options: [
        { value: 'P', label: 'estates.isPublished' },
        { value: 'U', label: 'estates.isPrivate' },
        { value: 'A', label: 'estates.isArchived' }
      ]
    }
  },
  {
    id: 'images',
    name: 'estates.images',
    show: false,
    fill: true,
    ro: true,
    tag: false,
    type: 'M'
  },
  {
    id: 'description',
    name: 'estates.description',
    show: false,
    fill: true,
    ro: true,
    tag: false,
    type: 'T',
    suggest: {
      source: 'templates',
      context: 'D'
    }
  },
  {
    id: 'plans',
    name: 'estates.plans',
    show: false,
    fill: true,
    ro: true,
    tag: false,
    type: 'M'
  },
  {
    id: 'summary',
    name: 'estates.summary',
    show: false,
    fill: true,
    ro: true,
    tag: false,
    type: 'T'
  },
  {
    id: 'visualizations',
    name: 'estates.visualizations',
    show: false,
    fill: true,
    ro: true,
    tag: false,
    type: 'N'
  },
  {
    id: 'createdAt',
    name: 'general.createdAt',
    show: true,
    fill: false,
    ro: true,
    tag: false,
    type: 'U'
  },
  {
    id: 'updatedAt',
    name: 'general.updatedAt',
    show: true,
    fill: false,
    ro: true,
    tag: false,
    type: 'U'
  }
]
