<template>
  <label
    class="flex flex-row select-none"
    :class="{
      'mb-4': !compact,
      'cursor-pointer hover:text-blue-800': !disabled,
      'cursor-not-allowed opacity-50': disabled
    }"
    @click.prevent="toggleChecked"
  >
    <a class="w-8 h-6 text-xl -mt-1"><icon :face="wIcon" /></a>
    <p class="mt-px pt-px text-sm">
      {{ wLabel }}
      <slot />
    </p>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: String,
    i18n: String,
    value: Boolean,
    disabled: Boolean,
    compact: { type: Boolean, default: false }
  },
  computed: {
    wLabel() {
      if (this.label) {
        return this.label
      }
      if (this.i18n) {
        return this.$t(this.i18n)
      }
      return ''
    },
    wIcon() {
      return this.value ? 'mdiCheckboxMarked' : 'mdiCheckboxBlankOutline'
    }
  },
  methods: {
    toggleChecked() {
      if (this.disabled) {
        return
      }
      this.$emit('input', !this.value)
    }
  }
}
</script>
