import { i18n } from '@/services/i18n'

const raw = [
  { value: 'A', disabled: false, label: 'array' },
  { value: 'B', disabled: false, taggable: true, label: 'bool' },
  { value: 'C', disabled: true, label: 'collection' },
  { value: 'D', disabled: false, label: 'date' },
  { value: 'F', disabled: false, label: 'file' },
  { value: 'G', disabled: false, label: 'geo' },
  { value: 'H', disabled: true, label: 'password' },
  { value: 'L', disabled: false, label: 'list' },
  { value: 'M', disabled: false, label: 'media' },
  { value: 'N', disabled: false, taggable: true, label: 'number' },
  { value: 'P', disabled: false, label: 'euro.sale' },
  { value: 'R', disabled: false, label: 'euro.rent' },
  { value: 'T', disabled: false, label: 'text' },
  { value: 'S', disabled: false, taggable: true, label: 'string' },
  { value: 'U', disabled: true, label: 'unix' },
  { value: 'W', disabled: false, label: 'warning' }
]

export const fieldTypes = raw.map((type) => ({
  ...type,
  label: i18n.t(`fieldTypes.${type.label}`)
}))

export const mappedFieldTypes = fieldTypes.reduce(
  (mapped, type) => ({
    ...mapped,
    [type.value]: type
  }),
  {}
)

export const getFieldTypeName = (header) => {
  if (header.type !== 'C') {
    return mappedFieldTypes?.[header.type]?.label || '?'
  }
  return i18n.t(header.source.label)
}
