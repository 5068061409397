<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <select
        class="
          w-full
          block
          bg-white
          m-0
          border border-gray-300
          rounded
          pr-4
          h-12
          px-4
        "
        :id="wId"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
        <option
          v-for="item in options"
          :key="item.id || item.value"
          :value="item.value"
        >
          {{ item.label }}
        </option>
      </select>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListInput',
  props: {
    heading: Object,
    value: [String, Array],
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    },
    options() {
      return this.heading.source.options.map((opt) => ({
        id: opt.id || opt.value,
        value: opt.value,
        label: this.heading.ro ? this.$t(opt.label) : opt.label
      }))
    }
  }
}
</script>
