import { default as types } from './types'
import { default as zones } from './zones'
import { default as estates } from './estates'
import { default as contacts } from './contacts'
import { default as templates } from './templates'

export const settings = {
  zones,
  types,
  estates,
  contacts,
  templates
}
