import http from './http'
import bus from './bus'

export default {
  list: async (collection, params) => {
    try {
      const response = await http.get(`/collection/${collection}`, { params })
      return response.data.data
    } catch (err) {
      console.error(
        `* Error while listing ${collection}`,
        err.response ? err.response.data : err
      )
      return null
    }
  },
  create: async (collection, data) => {
    try {
      const response = await http.post(`/collection/${collection}`, data)
      bus.$emit('toast', { type: 'success', message: 'messages.created' })
      return response.data.data
    } catch (err) {
      console.error(
        `* Error while creating ${collection}`,
        err.response ? err.response.data : err
      )
      bus.$emit('toast', { type: 'danger', message: 'errors.create' })
      return null
    }
  },
  read: async (collection, _id) => {
    try {
      if (!_id) {
        throw new Error(`Invalid _id on read from ${collection}`)
      }
      const response = await http.get(`/collection/${collection}/${_id}`)
      return response.data.data
    } catch (err) {
      console.error(
        `* Error while reading ${_id} from ${collection}`,
        err.response ? err.response.data : err
      )
      return null
    }
  },
  update: async (collection, _id, data) => {
    try {
      if (!_id) {
        throw new Error(`Invalid _id on update to ${collection}`)
      }
      const response = await http.put(`/collection/${collection}/${_id}`, data)
      bus.$emit('toast', { type: 'success', message: 'messages.updated' })
      return response.data.data
    } catch (err) {
      console.error(
        `* Error while updating ${_id} on ${collection}`,
        err.response ? err.response.data : err
      )
      bus.$emit('toast', { type: 'danger', message: 'errors.update' })
      return null
    }
  },
  destroy: async (collection, _id, params) => {
    try {
      if (!_id) {
        throw new Error(`Invalid _id on destroy on ${collection}`)
      }
      const response = await http.delete(`/collection/${collection}/${_id}`, {
        params
      })
      bus.$emit('toast', { type: 'success', message: 'messages.destroyed' })
      return response.data.data
    } catch (err) {
      console.error(
        `* Error while deleting ${_id} on ${collection}`,
        err.response ? err.response.data : err
      )
      const message =
        err.response &&
        err.response.data &&
        err.response.data.data &&
        err.response.data.data.message === 'ESTATE::ASSIGNED'
          ? 'errors.estateAssigned'
          : 'errors.destroy'
      bus.$emit('toast', { type: 'danger', message })
      return null
    }
  }
}
