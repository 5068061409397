import { i18n } from '@/services/i18n'

const _findLabel = (v, heading) =>
  heading.source.options.find((item) => item.value === v)

const _getLabels = (v, heading) => {
  if (heading.source.single) {
    const opt = _findLabel(v, heading)
    if (!opt?.label) {
      return '?'
    }
    return heading.ro ? i18n.t(opt.label) : opt.label
  }

  return v
    .map((val) => {
      const opt = _findLabel(val, heading)
      if (!opt?.label) {
        return null
      }
      return heading.ro ? i18n.t(opt.label) : opt.label
    })
    .filter((v) => v !== null)
}

export default {
  type: 'L',
  render: (value, { heading }) => {
    if (!value || !value.length) {
      return ''
    }

    const labels = _getLabels(value, heading)
    return heading.source.single ? labels : labels.sort().join(', ')
  },
  compare: (a, b, { heading, rendered }) => {
    if (!a?.length) {
      return -1
    }
    if (!b?.length) {
      return 1
    }

    if (rendered.a && rendered.b) {
      return rendered.a.localeCompare(rendered.b)
    }

    const aa = _getLabels(a, heading)
    const ab = _getLabels(b, heading)

    if (heading.source.single) {
      return aa.localeCompare(ab)
    }

    const sa = aa.sort().join(',')
    const sb = ab.sort().join(',')
    return sa.localeCompare(sb)
  }
}
