import Vue from 'vue'
import Vuex from 'vuex'

import moduleFactory from './moduleFactory'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    loading: false,
    loaded: false
  },
  getters: {
    logged: (state) => !!state.user,
    user: (state) => state.user,
    role: (state) => (state.logged ? state.user.role : null),
    loading: (state) => state.loading,
    loaded: (state) => state.loaded
  },
  mutations: {
    login: (state, user) => (state.user = user),
    logout: (state) => (state.user = null),
    loading: (state, status) => (state.loading = !!status),
    loaded: (state, status) => (state.loaded = !!status)
  },
  actions: {
    login({ commit, dispatch }, user) {
      commit('login', user)
      dispatch('load')
    },
    async load({ commit, dispatch }) {
      commit('loading', true)
      const loaders = [
        'settings',
        'headers',
        'images',
        'zones',
        'types',
        'contacts',
        'estates',
        'templates',
        'users'
      ]
      await Promise.all(loaders.map((res) => dispatch(`${res}/load`)))
      commit('loading', false)
      commit('loaded', true)
    }
  },
  modules: {
    settings: moduleFactory('settings'),
    headers: moduleFactory('headers'),
    images: moduleFactory('images'),
    zones: moduleFactory('zones'),
    types: moduleFactory('types'),
    contacts: moduleFactory('contacts'),
    estates: moduleFactory('estates'),
    templates: moduleFactory('templates'),
    users: moduleFactory('users')
  }
})
