<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <textarea
        class="w-full rounded border border-gray-300 block bg-white p-4"
        :style="{
          height: `${32 + (lines < 4 ? lines : 4) * 24}px`
        }"
        :id="wId"
        v-model="wModel"
      />
      <div class="h-4 text-gray-400 italic text-xs">
        {{ $t('general.onePerLine') }}
      </div>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrayInput',
  props: {
    heading: Object,
    value: Array,
    error: String
  },
  data() {
    return {
      display: null
    }
  },
  computed: {
    wId() {
      return this.heading.id
    },
    wModel: {
      get() {
        return this.display !== null
          ? this.display
          : this.value.length
          ? this.value.join('\n')
          : ''
      },
      set(value) {
        this.display = value
        this.$emit(
          'input',
          value
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => !!line)
        )
      }
    },
    lines() {
      return this.wModel?.split('\n').length ?? 1
    }
  }
}
</script>
