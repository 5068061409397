<template>
  <button
    class="
      rounded
      px-8
      h-12
      inline-flex
      justify-center
      items-center
      min-w-min
      text-xs
      uppercase
      tracking-wide
      whitespace-nowrap
      transform-gpu
      active:scale-95
    "
    :class="variantClass"
    :type="type"
    v-bind="$attrs"
    @click="(event) => $emit('click', event)"
  >
    <loading-icon class="-ml-1 mr-3 h-5 w-5" v-if="loading" />
    <slot />
  </button>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
import { actionVariants } from './actionVariants'

export default {
  name: 'Action',
  components: { 'loading-icon': LoadingIcon },
  inheritAttrs: false,
  props: {
    variant: { type: String, default: 'primary' },
    loading: Boolean,
    disabled: Boolean,
    type: { type: String, default: 'button' }
  },
  computed: {
    variantClass() {
      const _classes = actionVariants[this.variant] || ''
      return this.disabled
        ? `opacity-40 cursor-not-allowed pointer-events-none ${_classes}`
        : _classes
    }
  }
}
</script>
