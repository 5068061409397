export default [
  {
    id: 'name',
    name: 'types.name',
    show: true,
    fill: true,
    ro: true,
    main: true,
    type: 'S'
  },
  {
    id: 'context',
    name: 'templates.context',
    show: true,
    fill: true,
    ro: true,
    req: true,
    type: 'L',
    source: {
      single: true,
      options: [{ value: 'D', label: 'templates.description' }]
    }
  },
  {
    id: 'body',
    name: 'templates.body',
    show: false,
    fill: true,
    ro: true,
    req: true,
    type: 'T'
  },
  {
    id: 'createdAt',
    name: 'general.createdAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  },
  {
    id: 'updatedAt',
    name: 'general.updatedAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  }
]
