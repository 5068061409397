<template>
  <div class="w-full mb-2">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <draggable
      draggable=".item"
      handle=".item-handle"
      ghost-class="ghost"
      class="
        bg-white
        w-full
        rounded
        border border-gray-300
        p-2
        flex flex-row flex-wrap
      "
      v-model="wModel"
    >
      <div
        v-for="item in value"
        :key="item"
        class="
          relative
          bg-gray-200
          m-2
          w-32
          h-20
          bg-no-repeat bg-center bg-contain
          rounded
          item
        "
        :style="{ backgroundImage: `url(${getThumb(item)})` }"
      >
        <div
          class="
            text-xs
            absolute
            left-1
            top-1
            bg-blue-600
            text-blue-100
            rounded-sm
            uppercase
            px-1
            py-0.5
          "
        >
          {{ getExtension(item) }}
        </div>
        <div class="text-xs absolute inset-x-1 bottom-1 flex flex-col">
          <span class="text-xs overflow-hidden h-8">{{ getName(item) }}</span>
        </div>
        <div class="absolute inset-0 item-handle"></div>
        <button
          type="button"
          class="
            rounded-sm
            flex
            items-center
            justify-center
            bg-gray-400
            hover:bg-red-700
            w-6
            h-6
            text-white
            absolute
            top-0.5
            right-0.5
          "
          @click="onRemoveValue(item)"
        >
          <icon face="mdiWindowClose" />
        </button>
      </div>
      <button
        slot="footer"
        class="
          rounded
          inline-flex
          flex-col
          items-center
          justify-center
          m-2
          w-32
          h-20
          cursor-pointer
          select-none
          bg-green-600
          text-white
        "
        @click="showPicker"
      >
        <span class="text-xl"><icon face="mdiPlusCircleOutline" /></span>
        <span class="text-sm">{{ $t('actions.add') }}</span>
      </button>
    </draggable>
    <div class="h-4 text-xs text-red-700">
      {{ error }}
    </div>
    <backdrop v-if="pickerShown" @close="hidePicker">
      <div class="absolute top-3 inset-x-14 flex items-center justify-center">
        <media-heading
          no-filter
          :search="search"
          @search="onInputSearch"
          @upload="onImagesUpload"
        />
      </div>
      <div class="absolute top-14 inset-x-2 bottom-2 overflow-y-auto">
        <media-grid
          :search="search"
          pick="model"
          :selected="value"
          @select="(value) => $emit('input', value)"
        />
      </div>
    </backdrop>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import { getMediaURL } from '@/services'
import MediaHeading from '@/components/media/MediaHeading'
import MediaGrid from '@/components/media/MediaGrid'

// import fileIcon from '@/assets/file-icon.png'

export default {
  name: 'MediaInput',
  components: { draggable, MediaHeading, MediaGrid },
  props: {
    heading: Object,
    value: Array,
    error: String
  },
  data() {
    return {
      pickerShown: false,
      search: ''
    }
  },
  computed: {
    ...mapState({
      images: (state) => state.images
    }),
    wId() {
      return this.heading.id
    },
    mappedFiles() {
      if (!this.images.loaded) {
        return {}
      }
      return this.images.items.reduce(
        (all, img) => ({ ...all, [img._id]: img }),
        {}
      )
    },
    wModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions(['images/create']),
    showPicker() {
      this.pickerShown = true
    },
    hidePicker() {
      this.pickerShown = false
    },
    onInputSearch(value) {
      this.search = value
    },
    onRemoveValue(item) {
      const nv = this.value.slice(0)
      const idx = nv.findIndex((found) => found === item)
      if (idx >= 0) {
        nv.splice(idx, 1)
        this.$emit('input', nv)
      }
    },
    getThumb(_id) {
      const img = this.mappedFiles?.[_id]
      if (img) {
        return img.mime.startsWith('image/') ? getMediaURL(img) : ''
      }
      return ''
    },
    getName(_id) {
      const img = this.mappedFiles?.[_id]
      if (img) {
        return img.original
      }
      return ''
    },
    getExtension(_id) {
      const img = this.mappedFiles?.[_id]
      if (img) {
        return img.extension
      }
      return ''
    },
    async onImagesUpload(data) {
      const promises = data.map(
        (upload) => async () => this['images/create'](upload)
      )
      await Promise.all(promises.map((p) => p()))
    }
  }
}
</script>
