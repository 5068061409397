<template>
  <div class="chart">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      class="donut"
      :class="variant ? `donut-${variant}` : ''"
    >
      <circle
        v-if="hole"
        class="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
      ></circle>
      <circle
        v-if="ring"
        class="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke-width="stroke"
      ></circle>

      <circle
        v-for="segment in circles"
        :key="segment.id"
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke="segment.color"
        :stroke-width="stroke"
        :stroke-dasharray="segment.dash"
        :stroke-dashoffset="segment.offset"
      ></circle>
      <g class="chart-text" v-if="title || subtitle">
        <text v-if="title" x="50%" y="50%" class="chart-number">
          {{ title }}
        </text>
        <text v-if="subtitle" x="50%" y="50%" class="chart-label">
          {{ subtitle }}
        </text>
      </g>
    </svg>
    <div class="legend-panel mt-1">
      <ul class="legend">
        <li v-for="item in legendDisplay" :key="item.id">
          <span
            class="legend-color-index"
            :style="{ backgroundColor: item.color }"
          ></span>
          <span class="legend-label">{{ item.label }}:</span>
          <span class="legend-value">{{ item.value }}</span>
          <span class="legend-percent">({{ item.percent.toFixed(1) }}%)</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import chartColors from './colors'

export default {
  props: {
    variant: {
      type: String,
      default: ''
    },
    stroke: {
      type: Number,
      default: 5
    },
    rotate: {
      type: Number,
      default: 0
    },
    segments: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    title: [String, Number],
    subtitle: [String, Number],
    legend: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hole() {
      return false
    },
    ring() {
      return true
    },
    sum() {
      return (
        this.total ||
        this.segments.reduce((total, item) => total + item.value, 0)
      )
    },
    circles() {
      let globalOffset = 0
      const circles = this.segments.map((segment, idx) => {
        const id = `c-${idx}`
        const color = segment.color || this.getSegmentColor(idx)
        const percent = (segment.value * 100) / this.sum
        const dash = `${percent} ${100 - percent}`
        const offset = 100 - globalOffset + this.rotate + 25
        globalOffset += percent
        return { id, color, dash, offset }
      })
      return circles.slice(0, 4)
    },
    legendDisplay() {
      return this.segments.map((segment, idx) => {
        const id = `l-${idx}`
        const color = segment.color || this.getSegmentColor(idx)
        const percent = (segment.value * 100) / this.sum
        if (isNaN(percent)) {
          return { ...segment, id, color, percent: 0 }
        }
        return { ...segment, id, color, percent }
      })
    }
  },
  methods: {
    getSegmentColor(idx) {
      return chartColors[idx % chartColors.length]
    }
  }
}
</script>
