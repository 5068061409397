const render = (value) => {
  if (!value) {
    return 0
  }
  let pf = parseFloat(value)
  if (typeof value === 'object' && value?.p !== undefined) {
    pf = parseFloat(value.p)
  }
  return isNaN(pf) ? 0 : pf
}

const compare = (a, b) => render(a) - render(b)

export default {
  render,
  compare
}
