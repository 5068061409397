<template>
  <div class="flex">
    <action-icon
      @click="expand"
      face="mdiDeleteForeverOutline"
      v-if="!expanded"
      variant="danger"
    />
    <template v-else>
      <template v-if="iconsOnly">
        <action-icon
          @click="collapse"
          variant="success"
          class="mr-1"
          face="mdiChevronLeft"
          :disabled="busy"
        ></action-icon>
        <action
          @click="$emit('click')"
          variant="danger"
          class="ml-1 flex items-center"
          :disabled="!waited || busy"
          :loading="!waited || busy"
        >
          <span class="text-xl" v-if="waited"
            ><icon face="mdiDeleteForeverOutline" class="-mt-1 -ml-2"
          /></span>
          <span>{{ $t('actions.yesSure') }}</span></action
        >
      </template>
      <template v-else>
        <action
          @click="collapse"
          variant="success"
          class="mr-1"
          :disabled="busy"
          >{{ $t('actions.no') }}</action
        >
        <action
          @click="$emit('click')"
          variant="danger"
          class="ml-1"
          :disabled="!waited || busy"
          :loading="!waited || busy"
          >{{ $t('actions.yesSure') }}</action
        >
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    iconsOnly: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: true },
    busy: { type: Boolean, default: false }
  },
  data() {
    return {
      expanded: false,
      waited: false,
      tk: null
    }
  },
  mounted() {
    clearTimeout(this.tk)
    this.waited = false
  },
  beforeDestroy() {
    clearTimeout(this.tk)
  },
  methods: {
    expand() {
      this.expanded = true
      this.$emit('expanded', true)
      this.wait()
    },
    collapse() {
      this.expanded = false
      this.$emit('expanded', false)
    },
    wait() {
      clearTimeout(this.tk)
      this.waited = false
      this.tk = setTimeout(() => {
        this.waited = true
      }, 500)
    }
  }
}
</script>
