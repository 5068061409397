<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <div class="w-full flex items-center -mx-1">
        <div class="flex-1 mx-0.5">
          <input
            type="number"
            class="
              w-full
              rounded
              border border-gray-300
              block
              bg-white
              h-12
              px-4
              is-focusable
            "
            :id="wId"
            v-model.number="wPriceValue"
          />
        </div>
        <select
          v-if="isRent"
          class="
            bg-white
            m-0
            border border-gray-300
            rounded
            pr-4
            h-12
            px-4
            mx-0.5
          "
          v-model="wIntervalValue"
        >
          <option
            v-for="item in intervalOptions"
            :key="item.id"
            :value="item.id"
          >
            {{ $t(item.label) }}
          </option>
        </select>
        <select
          class="
            bg-white
            m-0
            border border-gray-300
            rounded
            pr-4
            h-12
            px-4
            mx-0.5
          "
          v-model="wUnitValue"
        >
          <option v-for="item in unitOptions" :key="item.id" :value="item.id">
            {{ $t(item.label) }}
          </option>
        </select>
      </div>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { priceOptions } from '@/config'

export default {
  name: 'PriceInput',
  props: {
    heading: Object,
    value: { type: Object, default: () => ({ p: '', u: 'M', i: 'A' }) },
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    },
    isRent() {
      return this.heading.type === 'R'
    },
    intervalOptions() {
      return priceOptions.intervalOptions
    },
    unitOptions() {
      return priceOptions.unitOptions
    },
    wPriceValue: {
      get() {
        return this.value?.p || ''
      },
      set(value) {
        const _v = typeof this.value === 'object' ? this.value : {}
        this.$emit('input', {
          ..._v,
          p: parseFloat(value)
        })
      }
    },
    wIntervalValue: {
      get() {
        return this.value?.i || 'A'
      },
      set(value) {
        const _v = typeof this.value === 'object' ? this.value : {}
        this.$emit('input', {
          ..._v,
          i: value
        })
      }
    },
    wUnitValue: {
      get() {
        return this.value?.u || 'M'
      },
      set(value) {
        const _v = typeof this.value === 'object' ? this.value : {}
        this.$emit('input', {
          ..._v,
          u: value
        })
      }
    }
  }
}
</script>
