<template>
  <div
    class="border border-gray-200 shadow-sm bg-white"
    :class="{
      'rounded-xl': !compact,
      rounded: compact
    }"
  >
    <header
      class="text-2xl text-indigo-800 font-light"
      :class="{
        'px-8 pt-8 rounded-t-xl': !compact,
        'px-2 pt-2 rounded-t': compact
      }"
      v-if="title"
    >
      {{ title }}
    </header>
    <article :class="{ 'm-8': !compact, 'm-2': compact }">
      <slot />
    </article>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    compact: Boolean
  }
}
</script>
