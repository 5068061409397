<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <button
        type="button"
        class="rounded-full w-20 h-8 border p-1 flex items-center"
        :class="{
          'flex-row bg-red-100 border-red-300': !value,
          'flex-row-reverse bg-green-100 border-green-300': !!value
        }"
        @click="toggle"
      >
        <template v-if="value">
          <span class="w-6 h-6 rounded-full bg-green-900"></span>
          <span class="flex-1 h-6 text-green-900">{{ $t('bool.yes') }}</span>
        </template>
        <template v-else>
          <span class="w-6 h-6 rounded-full bg-red-900"></span>
          <span class="flex-1 h-6 text-red-900">{{ $t('bool.no') }}</span>
        </template>
      </button>
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningInput',
  props: {
    heading: Object,
    value: Boolean,
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>
