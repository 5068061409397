<template>
  <page>
    <init-settings
      @done="settingsInitialized = true"
      v-if="!settingsInitialized"
    />
    <div class="flex md:flex-row max-w-2xl md:mx-auto">
      <div class="w-1/3">
        <donut
          class="mx-2"
          v-if="!loading"
          :segments="salesSegment"
          :title="$t('estates.transaction')"
          :total="estates.length"
          :subtitle="`${estates.length} ${$t('estates.nav')}`"
        ></donut>
      </div>
      <div class="w-1/3">
        <donut
          class="mx-2"
          v-if="!loading"
          :segments="statusSegment"
          :title="$t('estates.status')"
          :total="estates.length"
          :subtitle="`${estates.length} ${$t('estates.nav')}`"
        ></donut>
      </div>
      <div class="w-1/3">
        <donut
          class="mx-2"
          v-if="!loading"
          :segments="agencySegment"
          :title="$t('contacts.isAgency')"
          :total="estates.length"
          :subtitle="`${contacts.length} ${$t('contacts.nav')}`"
        ></donut>
      </div>
    </div>
  </page>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { Donut } from '@/components/chart'
import InitSettings from '@/components/InitSettings'
import mapper from '@/services/mapper'

export default {
  name: 'Home',
  components: { 'init-settings': InitSettings, donut: Donut },
  data() {
    return {
      settingsInitialized: false
    }
  },
  computed: {
    mappedContacts() {
      return mapper(this.contacts, '_id')
    },
    estateSegments() {
      const cm = this.mappedContacts
      return this.estates.reduce(
        (mapped, item) => {
          mapped[item.status] += 1

          if (item.transaction.length === 2) {
            mapped.RS += 1
          } else if (item.transaction.includes('S')) {
            mapped.S += 1
          } else {
            mapped.R += 1
          }
          const contact = cm[item.contactId]
          if (contact && contact.isAgency) {
            mapped.AG += 1
          } else if (contact) {
            mapped.NA += 1
          } else {
            mapped.NN += 1
          }
          return mapped
        },
        {
          R: 0,
          S: 0,
          RS: 0,
          P: 0,
          U: 0,
          A: 0,
          AG: 0,
          NA: 0,
          NN: 0
        }
      )
    },
    salesSegment() {
      const mapped = this.estateSegments
      const labels = {
        R: this.$t('estates.rental'),
        S: this.$t('estates.sale'),
        RS: this.$t('general.both')
      }
      return Object.keys(labels).map((key) => ({
        value: mapped[key],
        label: labels[key]
      }))
    },
    statusSegment() {
      const mapped = this.estateSegments
      const labels = {
        P: this.$t('estates.isPublished'),
        U: this.$t('estates.isPrivate'),
        A: this.$t('estates.isArchived')
      }
      return Object.keys(labels).map((key) => ({
        value: mapped[key],
        label: labels[key]
      }))
    },
    agencySegment() {
      const mapped = this.estateSegments
      const labels = {
        NA: this.$t('actions.no'),
        AG: this.$t('actions.yes'),
        NN: this.$t('general.unknown')
      }
      return Object.keys(labels).map((key) => ({
        value: mapped[key],
        label: labels[key]
      }))
    },
    ...mapState({
      loading: (state) => state.loading,
      estates: (state) => state.estates.items,
      contacts: (state) => state.contacts.items
    })
  }
}
</script>
