<template>
  <div class="w-full" :class="{ 'opacity-50': disabled }">
    <label :for="wId" v-if="wLabel" class="text-xs text-gray-800 font-bold">{{
      wLabel
    }}</label>
    <div :class="{ 'mb-2': !compact }">
      <select
        v-bind="$attrs"
        class="w-full block bg-white m-0 border border-gray-300 rounded pr-4"
        :id="wId"
        :name="wName"
        :value="value"
        :disabled="disabled"
        :class="{
          'h-12 px-4': !small,
          'h-8 px-3 text-sm': small
        }"
        @input="$emit('input', $event.target.value)"
      >
        <option
          v-for="item in options"
          :key="item.id || item.value"
          :value="item.value"
          :disabled="!!item.disabled"
        >
          {{ item.label }}
        </option>
      </select>
      <div class="h-4 text-xs text-red-700" v-if="!compact || !small">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Selector',
  inheritAttrs: false,
  props: {
    label: String,
    i18n: String,
    type: String,
    error: String,
    id: String,
    name: String,
    value: [String, Number],
    options: { type: Array, default: () => [] },
    compact: Boolean,
    small: Boolean,
    disabled: Boolean
  },
  computed: {
    wId() {
      return this.id || `field-${Math.floor(10000000 * Math.random())}`
    },
    wName() {
      return this.name || this.wId
    },
    wLabel() {
      if (this.label) {
        return this.label
      }
      if (this.i18n) {
        return this.$t(this.i18n)
      }
      return false
    }
  }
}
</script>
