<template>
  <div
    class="rounded tag inline-flex items-center"
    :class="{ 'flex-row-reverse': invert }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    invert: { type: Boolean, default: false }
  }
}
</script>
