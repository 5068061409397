<template>
  <div class="relative">
    <button @click.prevent="toggleExpand" class="">
      <slot name="trigger" />
    </button>
    <div
      class="
        absolute
        bg-white
        rounded
        border border-gray-300
        shadow
        min-w-dropdown
        -mt-px
        flex flex-col
        py-1
        top-full
        z-20
      "
      :class="{
        'left-0': align === 'left',
        'right-0': align === 'right'
      }"
      v-show="isExpanded"
    >
      <div class="w-3 h-3 absolute overflow-hidden -top-2 right-4">
        <div
          class="
            border-l border-t border-gray-300
            w-2
            h-2
            bg-white
            rotate-45
            transform
            top-1
            absolute
          "
        ></div>
      </div>
      <slot name="menu">
        <template v-for="item in items">
          <router-link
            v-if="item.to"
            :key="item.id"
            :to="item.to"
            class="px-6 py-3 hover:bg-active hover:text-inactive text-sm"
            >{{ $t(item.label) }}</router-link
          >
          <p
            :key="item.id"
            class="text-xs px-6 py-2 uppercase text-gray-400"
            v-else
          >
            {{ $t(item.label) }}
          </p></template
        >
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    expanded: Boolean,
    rightAlign: Boolean,
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isExpanded: !!this.expanded
    }
  },
  computed: {
    align() {
      return this.rightAlign ? 'right' : 'left'
    }
  },
  beforeMount() {
    document.addEventListener('click', this.onDocumentClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick)
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    onDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isExpanded = false
      }
    }
  }
}
</script>
