<template>
  <div class="w-full" :class="{ 'opacity-50': disabled }">
    <label :for="wId" v-if="wLabel" class="text-xs text-gray-800 font-bold">{{
      wLabel
    }}</label>
    <div :class="{ 'mb-2': !compact }">
      <input
        v-if="type !== 'textarea'"
        v-bind="$attrs"
        :type="wType"
        :disabled="disabled"
        class="w-full rounded border border-gray-300 block bg-white"
        :id="wId"
        :name="wName"
        :value="value"
        :class="{
          'h-12 px-4': !small,
          'h-8 px-3 text-sm  border-gray-400': small
        }"
        @input="onChange"
      />
      <textarea
        v-else
        v-bind="$attrs"
        :disabled="disabled"
        class="w-full rounded border border-gray-300 block bg-white p-4"
        :id="wId"
        :name="wName"
        :value="value"
        @input="onChange"
      />
      <div class="h-4 text-xs text-red-700" v-if="!compact || !small">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Field',
  fieldType: 'S',
  inheritAttrs: false,
  props: {
    label: String,
    i18n: String,
    type: String,
    error: String,
    id: String,
    name: String,
    value: [String, Number],
    compact: Boolean,
    small: Boolean,
    disabled: Boolean
  },
  computed: {
    wId() {
      return this.id || `field-${Math.floor(10000000 * Math.random())}`
    },
    wName() {
      return this.name || this.wId
    },
    wLabel() {
      if (this.label) {
        return this.label
      }
      if (this.i18n) {
        return this.$t(this.i18n)
      }
      return false
    },
    wType() {
      return this.type || 'text'
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('changed')
    }
  }
}
</script>
