<template>
  <div class="w-full">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="mb-2">
      <input
        type="number"
        class="
          w-full
          rounded
          border border-gray-300
          block
          bg-white
          h-12
          px-4
          is-focusable
        "
        :id="wId"
        v-model.number="wModel"
      />
      <div class="h-4 text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    heading: Object,
    value: Number,
    error: String
  },
  computed: {
    wId() {
      return this.heading.id
    },
    wModel: {
      get() {
        return this.value || 0
      },
      set(value) {
        this.$emit('input', parseFloat(value))
      }
    }
  }
}
</script>
