<template>
  <component
    v-bind:is="wTypeComponent"
    v-bind="$attrs"
    :value="value"
    :heading="heading"
    :error="error"
    @input="onChange"
  />
</template>

<script>
import DynamicInputs from '../form/dynamic'
import NullInput from '../form/dynamic/NullInput'

export default {
  name: 'DynamicField',
  inheritAttrs: false,
  props: {
    heading: { type: Object, default: () => null },
    value: [String, Number, Boolean, Array, Object],
    error: String
  },
  computed: {
    wTypeComponent() {
      let _type = this.heading.type
      if (_type === 'L' && this.heading.source.single === false) {
        _type = 'ML'
      }
      return DynamicInputs[_type] || NullInput
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('changed')
    }
  }
}
</script>
