import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import routes from '../routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.meta ? !!to.meta.public : false
  const checkRole = to.meta && to.meta.role ? to.meta.role : false
  const isLoggedIn = store.getters.logged
  const userRole = store.getters.role

  if (checkRole && userRole !== checkRole) {
    return next('/unauthorized')
  }

  if (!isPublic && !isLoggedIn) {
    return next('/login')
  }

  next()
})

export default router
