export default [
  {
    id: 'name',
    name: 'contacts.name',
    show: true,
    fill: true,
    ro: true,
    main: true,
    type: 'S'
  },
  {
    id: 'company',
    name: 'contacts.company',
    show: true,
    fill: true,
    ro: true,
    type: 'S'
  },
  {
    id: 'isAgency',
    name: 'contacts.isAgency',
    show: true,
    fill: true,
    ro: true,
    type: 'W'
  },
  {
    id: 'phones',
    name: 'contacts.phones',
    show: true,
    fill: true,
    ro: true,
    type: 'A'
  },
  {
    id: 'emails',
    name: 'contacts.emails',
    show: true,
    fill: true,
    ro: true,
    type: 'A'
  },
  {
    id: 'sites',
    name: 'contacts.sites',
    show: true,
    fill: true,
    ro: true,
    type: 'A'
  },
  {
    id: 'createdAt',
    name: 'general.createdAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  },
  {
    id: 'updatedAt',
    name: 'general.updatedAt',
    show: true,
    fill: false,
    ro: true,
    type: 'U'
  }
]
