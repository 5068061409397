<template>
  <nav role="navigation" class="flex flex-row items-center px-8">
    <button
      type="button"
      class="
        px-8
        pt-2
        -mb-px
        border
        rounded-t
        relative
        hover:bg-white
        select-none
      "
      :class="{
        'border-transparent': value !== tab.id,
        'border-gray-300 text-active bg-white pb-2': value === tab.id,
        'text-xs': size === 'xs',
        'text-md': size === 'md',
        'text-lg': size === 'lg'
      }"
      v-for="tab in tabs"
      :key="tab.id"
      @click="onClickTab(tab)"
    >
      {{ getLabel(tab) }}
      <span
        class="absolute inset-x-0 -bottom-px h-px bg-white"
        v-if="value === tab.id"
      ></span>
    </button>
  </nav>
</template>
<script>
export default {
  name: 'TabNav',
  props: {
    tabs: { type: Array, defautlt: () => [] },
    value: String,
    size: String
  },
  methods: {
    getLabel(tab) {
      return tab?.label || this.$t(tab.i18n)
    },
    onClickTab(tab) {
      this.$emit('input', tab.id)
    }
  }
}
</script>
