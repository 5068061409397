<template>
  <div
    class="fixed inset-0 bg-opacity-95 backdrop-blur-md z-30"
    :class="{
      'bg-white': color === 'white',
      'bg-gray-300': color === 'gray',
      'bg-gray-700': color === 'dark',
      'bg-black': color === 'black'
    }"
  >
    <button
      v-if="close"
      @click="$emit('close')"
      type="button"
      class="
        absolute
        right-2
        top-2
        px-2
        h-10
        text-sm
        flex
        items-center
        justify-center
        cursor-pointer
        bg-green-300
        text-green-900
        hover:text-black hover:bg-green-100
        rounded-xs
        shadow
        z-50
      "
    >
      {{ $t('actions.close') }}
      <icon face="mdiWindowClose" />
    </button>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    close: { type: Boolean, default: true },
    color: { type: String, default: 'gray' }
  },
  mounted() {
    document.body.classList.add('overflow-y-hidden')
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-y-hidden')
  }
}
</script>
