import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import moment from 'moment'

import langRo from '@/lang/ro'

const { _code } = langRo

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: _code, // set locale
  fallbackLocale: _code,
  messages: { ro: langRo } // set locale messages
})

const loadedLanguages = [_code] // our default language that is preloaded
moment.locale(_code)

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.documentElement.setAttribute('lang', lang)
  document.documentElement.lang = lang
  moment.locale(lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // prettier-ignore
  return import(/* webpackChunkName: "lang-[request]" */ '@/lang/' + lang + '.js')
    .then(messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    })
}

setI18nLanguage(_code)
