export const getMediaURL = ({ _id, name, extension }) =>
  `${process.env.VUE_APP_SERVER_URL}/media/images/${_id}-${encodeURI(
    name
  )}.${extension}`

export const getMediaDimensions = (image) =>
  new Promise((resolve) => {
    if (!image) {
      resolve(null)
    }
    const url = getMediaURL(image)
    const img = new Image()
    img.addEventListener('load', () => {
      resolve([img.width, img.height])
    })
    img.addEventListener('error', () => {
      resolve(null)
    })
    img.src = url
  })

export const getNiceSize = ({ size }, precision = 1) => {
  const units = ['b', 'k', 'm', 'g', 't']
  let result = size
  let idx = 0
  while (result > 1000 && units[idx]) {
    result = result / 1024
    idx += 1
  }
  return { value: result.toFixed(precision), unit: units[idx] }
}
