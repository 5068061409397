<template>
  <div
    class="
      inset-0
      w-screen
      h-screen
      bg-gray-500 bg-opacity-20
      backdrop-filter backdrop-blur-sm
      z-30
      fixed
    "
  >
    <div
      class="
        my-4
        mx-4
        relative
        z-40
        p-12
        bg-white
        shadow
        border border-gray-200
        rounded
        flex flex-col
      "
      :style="{ maxHeight: `calc(100vh - 32px)` }"
      :class="{
        'md:w-64 md:mx-auto': size === 'small',
        'md:w-80 md:mx-auto': size === 'medium',
        'md:w-2/3 md:mx-auto md:max-w-lg': size === 'default',
        'md:w-3/5 md:mx-auto lg:w-2/3': size === 'large'
      }"
    >
      <button
        class="
          absolute
          top-0
          right-0
          text-2xl text-gray-500
          w-12
          h-12
          rounded
          hover:text-active
        "
        v-if="close"
        @click="emitClose()"
      >
        &times;
      </button>
      <h4 class="text-xl -mt-2">{{ title || '' }}</h4>
      <div class="overflow-y-auto mt-4" ref="modalSlot">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String,
    close: { type: Boolean, default: true },
    size: { type: String, default: 'default' }
  },
  mounted() {
    document.body.classList.add('overflow-y-hidden')
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-y-hidden')
  },
  methods: {
    emitClose() {
      this.$emit('close')
    }
  }
}
</script>
