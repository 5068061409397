<template>
  <div
    class="
      bg-white bg-opacity-40
      filter
      blur-sm
      w-screen
      h-screen
      fixed
      inset-0
      flex
      items-center
      justify-center
      z-30
      cursor-wait
    "
  >
    <loading-icon class="h-1/6 max-h-12 text-blue-300" />
  </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'

export default {
  name: 'InitSettings',
  components: { LoadingIcon }
}
</script>
