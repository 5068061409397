<template>
  <div class="w-full mb-2">
    <label :for="wId" class="text-xs text-gray-800 font-bold">{{
      heading.name
    }}</label>
    <div class="flex items-center -mx-1">
      <div class="flex-1 mx-1 relative">
        <input
          class="w-full rounded border block bg-white h-12 px-4"
          :class="{ 'border-gray-300': !err, 'border-red-500': err }"
          :id="wId"
          :value="displayValue"
          readonly
          autocomplete="off"
        />
      </div>
      <action-icon
        variant="outline"
        face="mdiCrosshairsGps"
        class="mx-1"
        :spin="reading"
        @click="readCurrentLocation"
      />
      <action-icon
        variant="outline"
        face="mdiGoogleMaps"
        class="mx-1"
        @click="mapShown = !mapShown"
      />
    </div>
    <div class="h-4 text-xs text-red-700">
      {{ error }}
    </div>
    <backdrop
      v-if="mapShown"
      @close="mapShown = false"
      class="flex items-center justify-center"
    >
      <div
        class="
          absolute
          z-50
          top-2
          left-1/2
          -ml-48
          w-96
          h-10
          bg-white
          rounded-full
          shadow
          border border-gray-300
          text-center text-xs text-gray-600
          flex
          items-center
          justify-center
        "
      >
        <icon face="mdiMapMarker" class="mr-2" v-if="displayValue" />
        <span>{{ displayValue }}</span>
      </div>
      <div class="absolute inset-0">
        <GmapMap
          v-if="mapShown"
          :center="center"
          :zoom="zoom"
          :options="{ fullscreenControl: false }"
          @click="onMapClick"
          map-type-id="terrain"
          style="height: 100%"
        >
          <GmapMarker
            :position="center"
            :clickable="false"
            :draggable="false"
            @click="onMapClick"
          />
        </GmapMap>
      </div>
    </backdrop>
  </div>
</template>

<script>
export default {
  name: 'GeoInput',
  props: {
    heading: Object,
    value: { type: Object, default: () => ({}) },
    error: String
  },
  data() {
    return { reading: false, mapShown: false, err: false }
  },
  computed: {
    wId() {
      return this.heading.id
    },
    iasi() {
      return { lat: 47.16400985959647, lng: 27.58088294971545 }
    },
    center() {
      if (!this.value?.lat || !this.value?.lng) {
        return { ...this.iasi }
      }
      if (isNaN(this.value.lat) || isNaN(this.value.lng)) {
        return { ...this.iasi }
      }
      return this.value
    },
    displayValue() {
      if (this.err) {
        return this.$t('errors.geo')
      }
      if (this.reading) {
        return this.$t('messages.loading')
      }
      if (!this.value?.lat || !this.value?.lng) {
        return ''
      }
      return `${this.value.lat} × ${this.value.lng}`
    },
    zoom() {
      return 14
    }
  },
  methods: {
    onMapClick({ latLng }) {
      this.$emit('input', { lat: latLng.lat(), lng: latLng.lng() })
    },
    readCurrentLocation() {
      if (!navigator.geolocation) {
        this.err = true
        return
      }
      this.err = false
      this.reading = true
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.$emit('input', {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          this.err = false
          this.reading = false
        },
        (error) => {
          this.err = error
          this.reading = false
        }
      )
    }
  }
}
</script>
