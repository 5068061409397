<template>
  <div class="flex -mx-1">
    <action-icon
      v-if="showUpload"
      as="label"
      class="mx-1 cursor-pointer"
      variant="primary"
      face="mdiCloudUploadOutline"
      small
    >
      <input
        type="file"
        name="fileInput"
        class="hidden"
        multiple
        @change="beginFileUpload"
        ref="fileInput"
      />
    </action-icon>
    <div class="flex flex-1">
      <field
        v-if="showSearch"
        compact
        :placeholder="$t('general.search')"
        small
        class="mr-1"
        type="search"
        v-model="searchValue"
      />
      <selector
        v-if="showFilter"
        compact
        :options="fileTypes"
        small
        class="mr-1"
        v-model="wFilter"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: String,
    filter: String,
    showSearch: { type: Boolean, default: true },
    showFilter: { type: Boolean, default: false },
    showUpload: { type: Boolean, default: true }
  },
  data() {
    return { isBusy: false, tk: null, searchVal: this.search }
  },
  beforeDestroy() {
    clearTimeout(this.tk)
  },
  computed: {
    searchValue: {
      get() {
        return this.searchVal
      },
      set(value) {
        this.searchVal = value
        clearTimeout(this.tk)
        this.tk = setTimeout(() => {
          this.$emit('search', value)
        }, 250)
      }
    },
    wFilter: {
      get() {
        return this.filter
      },
      set(value) {
        this.$emit('filter', value)
      }
    },
    fileTypes() {
      return [
        { id: 'all', value: 'all', label: this.$t('media.filter.all') },
        { id: 'img', value: 'img', label: this.$t('media.filter.img') },
        { id: 'doc', value: 'doc', label: this.$t('media.filter.doc') }
      ]
    }
  },
  methods: {
    async beginFileUpload() {
      this.$bus.$emit('begin-images-upload')
      const files = this.$refs.fileInput.files
      if (!files || !files.length) {
        return
      }
      this.isBusy = true
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i])
      }
      try {
        const response = await this.$http.post('/media/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.$bus.$emit('images-upload', response.data.data)
      } catch (err) {
        this.$toast('danger', 'errors.mediaUpload')
      } finally {
        this.isBusy = false
        this.$bus.$emit('end-images-upload')
      }
    }
  }
}
</script>
