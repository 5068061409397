const render = (value) =>
  value && value.length ? value.map((v) => v.trim()).join(', ') : ''

export default {
  type: 'A',
  render,
  compare: (a, b, { rendered }) => {
    if (rendered.a && rendered.b) {
      return rendered.a.localeCompare(rendered.b)
    }
    return render(a).localeCompare(render(b))
  }
}
