import { i18n } from '@/services/i18n'

const mapper = (arr, by, render = (item) => item) =>
  arr.reduce(
    (mapping, item, idx) => ({
      ...mapping,
      [item[by]]: render(item, idx)
    }),
    {}
  )

const symbol = '€'
const disabledInterval = '_'

const intervalOptions = [
  { id: 'D', label: 'pricing.interval.day' },
  // { id: 'W', label: 'pricing.interval.week' },
  { id: 'M', label: 'pricing.interval.month' },
  { id: 'Y', label: 'pricing.interval.year' }
]

const mappedIntervalOptions = mapper(intervalOptions, 'id')

const unitOptions = [
  { id: 'A', label: 'pricing.unit.whole' },
  { id: 'U', label: 'pricing.unit.mp' }
]

const mappedUnitOptions = mapper(unitOptions, 'id')

const format = (price, forceSale) => {
  if (!price) {
    return '-'
  }
  const { p, i, u } = price
  if (!p && !i && !u) {
    // fallback for integer price
    return `${price} ${symbol}`
  }
  if (p === '') {
    return '-'
  }
  const interval = mappedIntervalOptions[i]
  const unit = mappedUnitOptions[u]

  return `${p !== undefined ? p : '-'} ${symbol} ${
    interval && !forceSale ? ` ${i18n.t(interval.label)}` : ''
  }${unit ? ` ${i18n.t(unit.label)}` : ''}`
}

const formatSimple = (price, includeUnit = false) => {
  if (!price) {
    return '-'
  }
  const { p } = price
  if (p === '') {
    return '-'
  }
  if (p === undefined && isNaN(price)) {
    return '-'
  }
  if (p === undefined && !isNaN(price)) {
    return `${price}${includeUnit ? ` ${symbol}` : ''}`
  }
  return `${p}${includeUnit ? ` ${symbol}` : ''}`
}

export default {
  symbol,
  format,
  formatSimple,
  disabledInterval,
  intervalOptions,
  unitOptions
}
