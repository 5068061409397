const _getRender = (item, heading, render) =>
  heading.reduce(
    (result, header) => ({
      ...result,
      [header.id]: render(header, item)
    }),
    {}
  )

const _addRender = (item, heading, render) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ...item,
        __render: _getRender(item, heading, render)
      })
    }, 1)
  })

export const getItemsRender = async (items, heading, render) => {
  const rendered = await Promise.all(
    items.map((item) => _addRender(item, heading, render))
  )

  return rendered || []
}
