<template>
  <section class="flex h-screen w-screen justify-center items-center">
    <slot />
    <toast />
  </section>
</template>

<script>
import Toast from '@/components/Toast'
export default {
  name: 'PageHero',
  components: { Toast }
}
</script>
