<template>
  <svg
    viewBox="0 0 24 24"
    class="inline-block"
    :style="svgStyle"
    :class="{ 'animate-spin': spin }"
  >
    <path :d="icon" :style="pathStyle" />
  </svg>
</template>

<script>
import {
  mdiAccount,
  mdiAccountEdit,
  mdiAlert,
  mdiAlertCircleOutline,
  mdiCrosshairsGps,
  mdiArrowExpandAll,
  mdiBell,
  mdiCancel,
  mdiCheck,
  mdiCheckboxMarked,
  mdiCheckboxBlankOutline,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCityVariantOutline,
  mdiCloudUploadOutline,
  mdiCog,
  mdiContacts,
  mdiContentCopy,
  mdiDeleteForeverOutline,
  mdiDotsVertical,
  mdiEmailSend,
  mdiEye,
  mdiEyeMinus,
  mdiEyeOffOutline,
  mdiEyePlus,
  mdiExportVariant,
  mdiFilterOutline,
  mdiFlagTriangle,
  mdiFullscreen,
  mdiGoogleMaps,
  mdiHome,
  mdiImage,
  mdiLoading,
  mdiMapCheckOutline,
  mdiMapMarker,
  mdiMenu,
  mdiMenuDown,
  mdiMenuSwap,
  mdiMenuUp,
  mdiOpenInNew,
  mdiPencil,
  mdiPencilMinus,
  mdiPencilPlus,
  mdiPhoneInTalk,
  mdiPlusCircleOutline,
  mdiPowerStandby,
  mdiRefresh,
  mdiStarCircle,
  mdiTable,
  mdiTableLargePlus,
  mdiTagMultipleOutline,
  mdiTagOffOutline,
  mdiTagText,
  mdiWeb,
  mdiWhatsapp,
  mdiWindowClose,
  mdiArchive
} from '@mdi/js'

export default {
  props: {
    face: String,
    color: String,
    size: String,
    aligned: Boolean,
    spin: Boolean
  },
  data() {
    return {
      available: {
        mdiAccount,
        mdiAccountEdit,
        mdiAlert,
        mdiAlertCircleOutline,
        mdiCrosshairsGps,
        mdiArrowExpandAll,
        mdiBell,
        mdiCancel,
        mdiCheck,
        mdiCheckboxBlankCircleOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
        mdiChevronDoubleLeft,
        mdiChevronDoubleRight,
        mdiChevronLeft,
        mdiChevronRight,
        mdiCityVariantOutline,
        mdiCloudUploadOutline,
        mdiCog,
        mdiContacts,
        mdiContentCopy,
        mdiDeleteForeverOutline,
        mdiDotsVertical,
        mdiEmailSend,
        mdiEye,
        mdiEyeMinus,
        mdiEyeOffOutline,
        mdiEyePlus,
        mdiExportVariant,
        mdiFilterOutline,
        mdiFlagTriangle,
        mdiFullscreen,
        mdiGoogleMaps,
        mdiHome,
        mdiImage,
        mdiLoading,
        mdiMapCheckOutline,
        mdiMapMarker,
        mdiMenu,
        mdiMenuDown,
        mdiMenuSwap,
        mdiMenuUp,
        mdiOpenInNew,
        mdiPencil,
        mdiPencilMinus,
        mdiPencilPlus,
        mdiPhoneInTalk,
        mdiPlusCircleOutline,
        mdiPowerStandby,
        mdiRefresh,
        mdiStarCircle,
        mdiTable,
        mdiTableLargePlus,
        mdiTagMultipleOutline,
        mdiTagOffOutline,
        mdiTagText,
        mdiWeb,
        mdiWhatsapp,
        mdiWindowClose,
        mdiArchive
      }
    }
  },
  computed: {
    icon() {
      return this.available[this.face] || this.available.mdiCog
    },
    pathStyle() {
      const pathStyle = {
        fill: 'currentColor',
        width: '16px',
        height: '16px'
      }
      if (this.color) {
        pathStyle.fill = this.color
      }
      if (this.size) {
        pathStyle.width = this.size
        pathStyle.height = this.size
      }
      return pathStyle
    },
    svgStyle() {
      const svgStyle = {
        width: '1em',
        height: '1em'
      }
      if (this.size) {
        svgStyle.width = `${this.size}`
        svgStyle.height = `${this.size}`
      }
      if (this.aligned) {
        svgStyle.position = 'relative'
        svgStyle.top = '0.125em'
      }
      return svgStyle
    }
  }
}
</script>
