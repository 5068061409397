<template>
  <component
    :is="as"
    class="
      rounded
      inline-flex
      items-center
      justify-center
      min-w-min
      uppercase
      tracking-wide
      whitespace-nowrap
      transform-gpu
      active:scale-95
    "
    :class="variantClass"
    :type="as === 'button' ? type : ''"
    v-bind="$attrs"
    @click="(event) => $emit('click', event)"
  >
    <icon :face="face" v-if="!loading" :spin="spin" />
    <svg
      v-else
      class="animate-spin h-5 w-5 text-current"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot />
  </component>
</template>

<script>
import { actionVariants } from './actionVariants'

export default {
  name: 'ActionIcon',
  inheritAttrs: false,
  props: {
    variant: { type: String, default: 'primary' },
    as: { type: String, default: 'button' },
    face: String,
    loading: Boolean,
    disabled: Boolean,
    type: { type: String, default: 'button' },
    small: Boolean,
    spin: { type: Boolean, default: false }
  },
  computed: {
    variantClass() {
      const _classes = actionVariants[this.variant]
        ? [actionVariants[this.variant]]
        : []
      if (this.disabled) {
        _classes.push('opacity-40 cursor-not-allowed pointer-events-none')
      }
      if (this.small) {
        _classes.push('h-8 w-8 text-sm')
      } else {
        _classes.push('h-12 w-12 text-xl')
      }

      return _classes.join(' ')
    }
  }
}
</script>
