const TOKEN_KEY = '_gi'
const CACHE_KEY = '_gi_cache'

class Storage {
  constructor() {
    this._memory = {}
  }

  get token() {
    return sessionStorage.getItem(TOKEN_KEY)
  }

  set token(value) {
    if (!value) {
      sessionStorage.removeItem(TOKEN_KEY)
    } else {
      sessionStorage.setItem(TOKEN_KEY, value)
    }
  }

  get cache() {
    return localStorage.getItem(CACHE_KEY)
  }

  set cache(value) {
    if (!value) {
      localStorage.removeItem(CACHE_KEY)
    } else {
      localStorage.setItem(CACHE_KEY, value)
    }
  }

  getItem(key, storage = 'memory') {
    const value =
      storage === 'local'
        ? localStorage.getItem(key)
        : storage === 'session'
        ? sessionStorage.getItem(key)
        : this._memory[key]
    if (!value) {
      return undefined
    }
    try {
      return JSON.parse(value)
    } catch (err) {
      console.warn('Invalid storage value', { value, err })
      return undefined
    }
  }

  setItem(key, item, storage = 'memory') {
    const value = JSON.stringify(item)
    switch (storage) {
      case 'local':
        localStorage.setItem(key, value)
        break
      case 'session':
        sessionStorage.setItem(key, value)
        break
      default:
        this._memory[key] = value
        break
    }
  }
}

export default new Storage()
