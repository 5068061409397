import switcher from './switcher'

const _renderSingleValue = (val) =>
  typeof val === 'boolean' ? switcher.render(val) : val

const _getDisplay = (
  value,
  { heading, collections, mainCollectionDisplay }
) => {
  const _value = Array.isArray(value) ? value : [value]
  const _collection = collections[heading.source.name]?.items || []
  const _headingMain = heading.source.field
    ? heading.source.field
    : mainCollectionDisplay[heading.source.name] || ''
  const result = _value.map(
    (v) => _collection?.find((item) => item._id === v)?.[_headingMain]
  )
  return result.map((v) =>
    Array.isArray(v)
      ? v.map(_renderSingleValue).join(', ')
      : _renderSingleValue(v)
  )
}

export default {
  type: 'C',
  render: (value, { heading, collections, mainCollectionDisplay }) =>
    _getDisplay(value, { heading, collections, mainCollectionDisplay })
      .sort()
      .join(', '),
  compare: (
    a,
    b,
    { heading, rendered, collections, mainCollectionDisplay }
  ) => {
    if (rendered.a && rendered.b) {
      return rendered.a.localeCompare(rendered.b)
    }
    const va = _getDisplay(a, { heading, collections, mainCollectionDisplay })
    const vb = _getDisplay(b, { heading, collections, mainCollectionDisplay })

    const sa = va.sort().join(',')
    const sb = vb.sort().join(',')
    return sa.localeCompare(sb)
  }
}
