const validators = require('./validators')

const _isValid = (value, rule) => {
  let [ruleName, ruleArgs] = rule.split(':', 2)
  if (!ruleArgs) {
    ruleArgs = []
  }
  if (!validators[ruleName]) {
    return `invalid_rule:${ruleName}`
  }
  return validators[ruleName](value, ...ruleArgs)
}

export default (rules, payload) =>
  Object.keys(rules).reduce((errors, attribute) => {
    const fieldRules = rules[attribute]
    let failRule = null
    const err = fieldRules.some((rule) => {
      failRule = rule
      return !_isValid(payload[attribute], rule)
    })
    if (err) {
      errors[attribute] = failRule
    }
    return errors
  }, {})
